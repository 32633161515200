import { type UseFetchError } from '~/types/Error'
import { BaseError } from '~/errors/BaseError'

export interface NetworkErrorConstructor {
  statusCode: number | string
  error: string
  message: string
  timestamp?: string | number
}

export class NetworkError extends BaseError {
  statusCode: number | string

  constructor(errorObj: NetworkErrorConstructor) {
    super(
      errorObj.message,
      errorObj.error,
      errorObj.timestamp || new Date().toISOString(),
    )
    this.name = 'NetworkError'
    this.statusCode = errorObj.statusCode
  }

  static fromUseFetchError(errorObj: UseFetchError) {
    const statusCode = errorObj.data?.statusCode || errorObj.statusCode || 500

    const error =
      errorObj.data?.error || errorObj.statusMessage || 'Unknown Status'

    let message = `${statusCode.toString()}: ${error}`
    if (errorObj.message) {
      message += `\nFetchError: ${errorObj.message}`
    }
    if (errorObj.data?.message) {
      const serverMsg =
        errorObj.data.message instanceof Array
          ? errorObj.data.message.join(', ')
          : errorObj.data.message
      message += `\nServerMessage: ${serverMsg}`
    }

    const timestamp = errorObj.data?.timestamp || new Date().toISOString()

    return new NetworkError({
      statusCode,
      error,
      message,
      timestamp,
    })
  }
}
