export function upsertArrayById<T extends { id: string }>(
  existingList: T[],
  newList: T[],
): T[] {
  newList.forEach((newItem) => {
    const existingItemIndex = existingList.findIndex(
      (existingItem) => existingItem.id === newItem.id,
    )

    if (existingItemIndex === -1) {
      existingList.push(newItem)
    } else {
      existingList[existingItemIndex] = newItem
    }
  })

  return existingList
}
