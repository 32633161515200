<template>
  <AppDialog
    v-model="isDialogVisible"
    :is-closable="false"
    header="Session Expired"
    message="Your session has expired. Please sign in again to continue."
  >
    <template #actions>
      <AppButtonPrimary
        label="Sign In"
        @click="$emit('resolve')"
      />
    </template>
  </AppDialog>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
})

const isDialogVisible = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const emit = defineEmits(['update:modelValue', 'resolve', 'close'])
</script>
