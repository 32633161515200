import type { MockFileType } from '~/types/Simulation'

export function getMimeTypeFromMockFileType(mockType: MockFileType): string {
  const mimeTypeMap = {
    Document: 'application/msword',
    Image: 'image/jpg',
    Pdf: 'application/pdf',
    Slideshow: 'application/vnd.ms-powerpoint',
    Video: 'video/mp4',
  }

  return mimeTypeMap[mockType] ?? 'application/pdf'
}
