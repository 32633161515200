<template>
  <AppDialog
    v-model="isDialogOpen"
    :header="`Delete ${toTitleCase(itemName)}`"
    :message="`Are you sure you want to delete this ${itemName}?${
      additionalText ? ` ${additionalText}` : ''
    }`"
    @click:close="$emit('close')"
  >
    <template #actions>
      <AppButtonSecondary
        label="Cancel"
        @click="handleCancel"
      />
      <AppButtonPrimary
        :label="`Delete ${toTitleCase(itemName)}`"
        @click="handleDelete"
      />
    </template>
  </AppDialog>
</template>

<script setup lang="ts">
import { toTitleCase } from '~/utils/toTitleCase'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  itemName: {
    type: String,
    required: true,
  },
  additionalText: {
    type: String,
    required: false,
    default: undefined,
  },
})

const emit = defineEmits(['update:modelValue', 'resolve', 'close'])

const isDialogOpen = computed({
  get() {
    return props.modelValue
  },
  set(value: boolean) {
    emit('update:modelValue', value)
  },
})

function handleDelete() {
  emit('resolve', true)
  isDialogOpen.value = false
}

function handleCancel() {
  emit('resolve', false)
  isDialogOpen.value = false
}
</script>
