<template>
  <div
    class="h-screen w-screen bg-surface-100 px-8 flex justify-center items-start flex-col gap-2 text-surface-800"
  >
    <h1 class="text-5xl font-bold">Whoops!</h1>
    <h2 class="text-xl font-medium">Seems like something went wrong.</h2>
    <span v-if="error.statusCode"
      >Yeah, I'd say that's a
      <span class="font-semibold">{{ error.statusCode }}.</span></span
    >
    <span class="text-sm">{{ error.statusMessage }}</span>
    <div class="flex gap-2 mt-2">
      <AppButtonPrimary
        label="Refresh"
        @click="handleStartAgain"
      />
      <AppButtonSecondary
        v-if="authStore.isAuthenticated"
        label="Sign Out"
        @click="handleSignOut"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { type NuxtError } from '#app'
import { useAuthStore } from '~/stores/auth'

defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true,
  },
})

const authStore = useAuthStore()

function handleSignOut() {
  clearError()
  authStore.signOut()
}

function handleStartAgain() {
  clearError()
  window.location.reload()
}
</script>
