import { createAuth0 } from '@auth0/auth0-vue'
import { useRuntimeConfig } from 'nuxt/app'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { auth0 },
  } = useRuntimeConfig()

  const auth0Plugin = createAuth0({
    authorizationParams: {
      redirect_uri: auth0.redirectUri,
      audience: auth0.audience,
    },
    clientId: auth0.clientId,
    domain: auth0.domain,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
  })

  nuxtApp.vueApp.use(auth0Plugin)
})
