import { httpService } from '~/services/httpService'
import type { ShareableEntityList } from '~/types/ShareableEntityList'
import type { Test } from '~/types/Test'
import type {
  PopulatedTestAttempt,
  QuestionAttempt,
  TestAttempt,
  TestAttemptWithGradeAndCandidate,
} from '~/types/TestAttempt'

export function testService() {
  const config = useRuntimeConfig()
  const serverUrl = config.public.serverUrl

  const { getResource, deleteResource, postResource, patchResource } =
    httpService<Test>(serverUrl)

  async function getTests(queryParameters: Record<string, string> = {}) {
    const response = await getResource<Test[]>('/tests', {
      query: queryParameters,
    })
    return response
  }

  async function getTestById(testId: string) {
    const response = await getResource(`tests/${testId}`, {
      useFallbackAuth: true,
    })
    return response
  }

  async function getWorkspaceTests(workspaceId: string) {
    const response = await getResource<ShareableEntityList<Test>>(
      `/workspaces/${workspaceId}/tests`,
    )
    return response
  }

  async function postTest(workspaceId: string, body: Partial<Test>) {
    const response = await postResource(
      `/workspaces/${workspaceId}/tests`,
      body,
    )
    return response
  }

  async function updateTestById(
    workspaceId: string,
    testId: string,
    body: Partial<Test>,
  ) {
    const response = await patchResource(
      `/workspaces/${workspaceId}/tests/${testId}`,
      body,
    )
    return response
  }

  async function deleteTestById(workspaceId: string, testId: string) {
    const response = await deleteResource(
      `/workspaces/${workspaceId}/tests/${testId}`,
    )
    return response
  }

  async function getWorkspaceTestUpdates(
    workspaceId: string,
    updatedAtGt: string,
  ) {
    const response = await getResource<ShareableEntityList<Test>>(
      `/workspaces/${workspaceId}/tests`,
      {
        query: {
          updatedAtGt,
        },
      },
    )
    return response
  }

  async function createTestAttempt(testId: string, body: Partial<TestAttempt>) {
    const response = await postResource(`/tests/${testId}/attempts`, body)
    return response
  }

  async function updateTestAttempt(
    testId: string,
    testAttemptId: string,
    body: Partial<TestAttempt>,
  ) {
    const response = await patchResource(
      `/tests/${testId}/attempts/${testAttemptId}`,
      body,
      {
        useFallbackAuth: true,
      },
    )
    return response
  }

  async function deleteTestAttempt(testId: string, testAttemptId: string) {
    const response = await deleteResource(
      `/tests/${testId}/attempts/${testAttemptId}`,
    )
    return response
  }

  async function createQuestionAttempt(
    questionId: string,
    body: Partial<QuestionAttempt>,
  ) {
    const response = await postResource(
      `/questions/${questionId}/attempts`,
      body,
    )
    return response
  }

  async function updateQuestionAttempt(
    questionId: string,
    questionAttemptId: string,
    body: Partial<QuestionAttempt>,
  ) {
    const response = await patchResource(
      `/questions/${questionId}/attempts/${questionAttemptId}`,
      body,
      {
        useFallbackAuth: true,
      },
    )
    return response
  }

  async function deleteQuestionAttempt(
    questionId: string,
    questionAttemptId: string,
  ) {
    const response = await deleteResource(
      `/questions/${questionId}/attempts/${questionAttemptId}`,
    )
    return response
  }

  async function initialiseTestAttempt(testId: string, candidateId: string) {
    const response = await postResource<
      {
        testAttempt: TestAttempt
        questionAttempts: QuestionAttempt[]
      },
      { candidateId: string }
    >(
      `/tests/${testId}/attempts/initialize`,
      {
        candidateId,
      },
      {
        useFallbackAuth: true,
      },
    )
    return response
  }

  async function getTestAttemptsByTestId(testId: string) {
    const response = await getResource<TestAttemptWithGradeAndCandidate[]>(
      `/tests/${testId}/attempts`,
    )
    return response
  }

  async function getTestAttemptById(testId: string, testAttemptId: string) {
    const response = await getResource<PopulatedTestAttempt>(
      `/tests/${testId}/attempts/${testAttemptId}`,
    )
    return response
  }

  async function requestTestAttemptGrade(
    testId: string,
    testAttemptId: string,
  ) {
    const response = await postResource<string, undefined>(
      `/tests/${testId}/attempts/${testAttemptId}/request/grade`,
      undefined,
    )
    return response
  }

  async function requestTestAttemptReGrade(
    testId: string,
    testAttemptId: string,
  ) {
    const response = await postResource<string, undefined>(
      `/tests/${testId}/attempts/${testAttemptId}/request/re-grade`,
      undefined,
    )
    return response
  }

  return {
    getTests,
    getTestById,
    getWorkspaceTests,
    postTest,
    updateTestById,
    deleteTestById,
    getWorkspaceTestUpdates,
    createTestAttempt,
    updateTestAttempt,
    deleteTestAttempt,
    createQuestionAttempt,
    updateQuestionAttempt,
    deleteQuestionAttempt,
    initialiseTestAttempt,
    getTestAttemptsByTestId,
    getTestAttemptById,
    requestTestAttemptGrade,
    requestTestAttemptReGrade,
  }
}
