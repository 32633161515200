import { httpService } from '~/services/httpService'
import type { Workspace, WorkspaceWithUsers } from '~/types/Workspace'

export function workspaceService() {
  const config = useRuntimeConfig()
  const serverUrl = config.public.serverUrl

  const { getResource, deleteResource, postResource, patchResource } =
    httpService<Workspace>(serverUrl)

  async function getWorkspaces(queryParameters: Record<string, string> = {}) {
    const response = await getResource<Workspace[]>('/workspaces', {
      query: queryParameters,
    })
    return response
  }

  async function getWorkspaceById(workspaceId: string) {
    const response = await getResource<WorkspaceWithUsers>(
      `workspaces/${workspaceId}`,
    )
    return response
  }

  async function postWorkspace(body: Partial<Workspace>) {
    const response = await postResource(`/workspaces`, body)
    return response
  }

  async function updateWorkspaceById(
    workspaceId: string,
    body: Partial<Workspace>,
  ) {
    const response = await patchResource(`/workspaces/${workspaceId}`, body)
    return response
  }

  async function deleteWorkspaceById(workspaceId: string) {
    const response = await deleteResource(`/workspaces/${workspaceId}`)
    return response
  }

  async function addUsersToWorkspace(
    workspaceId: string,
    userEmails: string[],
  ) {
    const response = await postResource<WorkspaceWithUsers, string[]>(
      `/workspaces/${workspaceId}/users/add-by-email`,
      userEmails,
    )
    return response
  }

  async function removeUserFromWorkspace(workspaceId: string, userId: string) {
    const response = await deleteResource(
      `/workspaces/${workspaceId}/users/${userId}`,
    )
    return response
  }

  return {
    getWorkspaces,
    getWorkspaceById,
    postWorkspace,
    updateWorkspaceById,
    deleteWorkspaceById,
    addUsersToWorkspace,
    removeUserFromWorkspace,
  }
}
