<template>
  <div
    class="rounded-lg border border-solid border-surface-300 shadow-sm h-11 px-3 bg-white focus-within:border-primary-500 hover:border-gray-400 focus-within:hover:border-primary-500 duration-150 flex items-center gap-2"
    :class="{
      '!border-error-400': invalid,
    }"
    tabindex="0"
    @focus="focusInput"
  >
    <!-- PREPEND ICON -->
    <div
      v-if="prependIcon"
      class="h-4 w-4 flex items-center justify-center shrink-0"
      :class="{
        'cursor-pointer text-surface-500 hover:text-surface-600':
          isPrependIconButton,
        'text-surface-400': !isPrependIconButton,
      }"
      @click="clickPrependIcon"
    >
      <font-awesome-icon
        :icon="prependIcon"
        class="text-base"
        fixed-width
      />
    </div>

    <!-- INPUT -->
    <input
      :id="id"
      ref="inputRef"
      v-model="inputValue"
      v-bind="inputAttrs"
      :type="type"
      class="text-xs w-full font-medium outline-none placeholder:text-gray-400"
      :class="{
        'text-center': centerText,
      }"
      :readonly="readonly"
    />

    <!-- APPEND ICON -->
    <div
      v-if="appendIcon || (clearable && inputValue)"
      class="h-4 w-4 flex items-center justify-center shrink-0 duration-150"
      :class="{
        'cursor-pointer text-surface-500 hover:text-surface-600':
          isAppendIconButton || clearable,
        'text-surface-400': !isAppendIconButton && !clearable,
      }"
      @click="clickAppendIcon"
    >
      <font-awesome-icon
        :icon="clearable && inputValue ? 'fa-regular fa-xmark' : appendIcon"
        class="text-base"
        fixed-width
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: false,
    default: 'text',
  },
  prependIcon: {
    type: String,
    required: false,
    default: undefined,
  },
  isPrependIconButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  appendIcon: {
    type: String,
    required: false,
    default: undefined,
  },
  isAppendIconButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  centerText: {
    type: Boolean,
    required: false,
    default: false,
  },
  invalid: {
    type: Boolean,
    required: false,
    default: false,
  },
  id: {
    type: String,
    required: false,
    default: undefined,
  },
  readonly: {
    type: Boolean,
    required: false,
    default: false,
  },
  clearable: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits([
  'update:modelValue',
  'clickPrependIcon',
  'clickAppendIcon',
])

const inputValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

// class attr is unused
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { class: unusedFieldClass, id: unusedFieldId, ...inputAttrs } = useAttrs()

const inputRef = ref<HTMLInputElement | null>(null)

function focusInput() {
  inputRef.value?.focus()
}

function clickPrependIcon() {
  emit('clickPrependIcon')
}

function clickAppendIcon() {
  emit('clickAppendIcon')

  if (props.clearable) {
    inputValue.value = ''
  }
}
</script>
