<template>
  <AppDialog
    v-model="isDialogOpen"
    :header="heading"
    :message="message"
    @click:close="$emit('close')"
  >
    <AppInputText
      v-model="readOnlyValue"
      :append-icon="copyIcon"
      :is-append-icon-button="true"
      readonly
      @click-append-icon="handleClickCopy"
    />

    <template #actions>
      <AppButtonPrimary
        label="Close"
        @click="handleConfirm"
      />
    </template>
  </AppDialog>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  heading: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: false,
    default: undefined,
  },
  value: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue', 'resolve', 'close'])

const isDialogOpen = computed({
  get() {
    return props.modelValue
  },
  set(value: boolean) {
    emit('update:modelValue', value)
  },
})

const readOnlyValue = computed({
  get() {
    return props.value
  },
  set(_: string) {
    // Do nothing
  },
})

const copyIcon = ref('fa-regular fa-clone')

function handleConfirm() {
  emit('resolve')
  isDialogOpen.value = false
}

async function handleClickCopy() {
  await navigator.clipboard.writeText(props.value)
  copyIcon.value = 'fa-regular fa-check'

  setTimeout(() => {
    copyIcon.value = 'fa-regular fa-clone'
  }, 3000)
}
</script>
