<template>
  <Toast
    group="interaction-end"
    position="bottom-left"
    style="z-index: 999"
  >
    <template #container>
      <div class="p-4 flex justify-between items-center gap-1">
        <!-- START -->
        <div class="flex flex-col items-start justify-center gap-1">
          <span class="text-sm font-semibold text-surface-800"
            >Simulation Complete</span
          >
          <span class="text-2xs font-normal text-surface-600"
            >You may continue to browse the simulation and exit when you are
            ready.</span
          >
        </div>

        <!-- END -->
        <div class="shrink-0">
          <AppButtonIcon
            v-tooltip="'Exit Simulation'"
            icon="fa-regular fa-person-walking-arrow-right"
            size="lg"
            @click="handleExit"
          />
        </div>
      </div>
    </template>
  </Toast>
</template>

<script setup lang="ts">
import Toast from 'primevue/toast'

import { useInteractionControlStore } from '~/stores/interactionControl'
const interactionControlStore = useInteractionControlStore()

const { removeToastsByType } = useToastNotifications()

function handleExit() {
  interactionControlStore.closeInteraction()
  removeToastsByType('interaction-end')
}
</script>
