<template>
  <AppDialog
    v-model="isDialogOpen"
    :header="heading"
    :message="message"
    @click:close="$emit('close')"
  >
    <AppInputText
      v-model="inputValue"
      :placeholder="inputPlaceholder"
      autofocus
      @keyup.enter="handleConfirm"
    />

    <DialogLoadingScrim :loading="isLoading" />

    <template #actions>
      <AppButtonSecondary
        label="Cancel"
        @click="handleCancel"
      />
      <AppButtonPrimary
        :disabled="!inputValue"
        :label="confirmActionLabel"
        @click="handleConfirm"
      />
    </template>
  </AppDialog>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  heading: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: false,
    default: undefined,
  },
  inputPlaceholder: {
    type: String,
    required: false,
    default: undefined,
  },
  confirmActionLabel: {
    type: String,
    required: false,
    default: 'Create',
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue', 'resolve', 'close'])

const isDialogOpen = computed({
  get() {
    return props.modelValue
  },
  set(value: boolean) {
    emit('update:modelValue', value)
  },
})

const inputValue = ref('')

function handleConfirm() {
  emit('resolve', inputValue.value)
  isDialogOpen.value = false
}

function handleCancel() {
  emit('resolve', null)
  isDialogOpen.value = false
}
</script>
