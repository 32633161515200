import { defineStore } from 'pinia'
import moment, { type Moment } from 'moment'
import { type ComposeInput, type Email, type EmailUser } from '~/types/Email'
import { useInteractionControlStore } from '~/stores/interactionControl'
import {
  SimulationType,
  type PopulatedStockDirectEmail,
  EmailSkin,
} from '~/types/Simulation'
import type { Artefact } from '~/types/Artefact'
import type { Character } from '~/types/Character'
import type { EmailMessage } from '~/types/Interaction'
import { getShortEmailDateStringFromDate } from '~/utils/dates'
import { useCandidateStore } from '@/stores/candidate'

export const useEmailStore = defineStore('email', () => {
  // OTHER STORES ====================================
  const interactionControllerStore = useInteractionControlStore()
  const candidateStore = useCandidateStore()

  const interactionState = computed(
    () => interactionControllerStore.interactionState,
  )

  const canCandidateCompose = computed(() => {
    if (!interactionControllerStore.simulation) return false

    return (
      interactionControllerStore.simulation.type === SimulationType.emailCreate
    )
  })

  const startTime = computed(() => {
    if (!interactionControllerStore.simulation)
      return getMomentDateNow().format('HH:mm')

    if (!interactionControllerStore.simulation.startTime)
      return getMomentDateNow().format('HH:mm')

    const validTimeStringRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/

    if (
      !validTimeStringRegex.test(
        interactionControllerStore.simulation.startTime,
      )
    )
      return getMomentDateNow().format('HH:mm')

    return interactionControllerStore.simulation.startTime
  })

  const { setActualStartTime, getOffsetTimestamp } =
    useFictitiousTime(startTime)

  const hasError = ref(false)

  const skin = computed(() => {
    if (!interactionControllerStore.simulation) return EmailSkin.Google

    return interactionControllerStore.simulation.emailSkin ?? EmailSkin.Google
  })

  const page = ref<'inbox' | 'sent'>('inbox')

  function setPage(newPage: 'inbox' | 'sent') {
    page.value = newPage
  }

  const mockEmails = ref<Email[]>([])

  const interactiveEmails = ref<Email[]>([])

  function getEmailUserFromCandidate(): EmailUser {
    if (!candidateStore.candidate) {
      return {
        fullName: 'You',
        avatarUrl: '',
      }
    }

    return {
      fullName: `${candidateStore.candidate.firstName} ${candidateStore.candidate.lastName}`,
      avatarUrl: '',
    }
  }

  function getEmailFromThreadEmail(threadEmail: EmailMessage): Email | null {
    if (!interactionControllerStore.simulation) return null

    return {
      id: getNewId(),
      sender:
        threadEmail.author === 'candidate'
          ? getEmailUserFromCandidate()
          : interactionControllerStore.simulation.character,
      recipient:
        threadEmail.author === 'candidate'
          ? interactionControllerStore.simulation.character.fullName
          : 'me',
      subjectLine: threadEmail.subject,
      text: threadEmail.text,
      timestamp: threadEmail.timestamp,
      isUnread: false,
      isInteractive: true,
      file: threadEmail.file ?? null,
      users: [],
    }
  }

  watch(
    () => [...interactionControllerStore.thread],
    (newThread, oldThread) => {
      const oldTimestamps = oldThread?.map((entry) => entry.timestamp) || []

      newThread
        .filter(
          (entry) => entry.type === 'message' && entry.messageType === 'email',
        )
        .filter((entry) => !oldTimestamps.includes(entry.timestamp))
        .forEach((entry) => {
          const email = getEmailFromThreadEmail(entry)

          if (!email) return

          if (email.recipient === 'me') {
            email.isUnread = true

            email.timestamp = getShortEmailDateStringFromDate(
              getOffsetTimestamp(email.timestamp),
            )
          }

          interactiveEmails.value.unshift(email)
        })
    },
    { immediate: true, deep: true },
  )

  const allEmails = computed<Email[]>(() => {
    return interactiveEmails.value.concat(mockEmails.value)
  })

  const inbox = computed<Email[]>(() => {
    return allEmails.value.filter((email) => email.recipient === 'me')
  })

  const inboxUnreadCount = computed(() => {
    return inbox.value.filter((email) => email.isUnread).length
  })

  const sentEmails = computed<Email[]>(() => {
    return allEmails.value.filter((email) => email.recipient !== 'me')
  })

  const sentEmailsUnreadCount = computed(() => {
    return sentEmails.value.filter((email) => email.isUnread).length
  })

  const activeList = computed(() => {
    if (page.value === 'inbox') {
      return inbox.value.filter((email) => email.recipient === 'me')
    }

    if (page.value === 'sent') return sentEmails.value

    return []
  })

  function getEmailById(emailId: string) {
    return allEmails.value.find((email) => email.id === emailId) ?? null
  }

  const activeEmailId = ref<string | null>(null)

  const activeEmail = computed<Email | null>(() => {
    if (!activeEmailId.value) return null

    return getEmailById(activeEmailId.value) ?? null
  })

  function markEmailAsRead(emailId: string) {
    const email = getEmailById(emailId)

    if (!email) return

    email.isUnread = false
  }

  function setActiveEmail(id: string | null) {
    activeEmailId.value = id

    if (!id) return

    markEmailAsRead(id)
  }

  const isComposeWindowOpen = ref(false)

  const composeInput = ref<ComposeInput>({
    subjectLine: '',
    recipient: '',
    text: '',
    file: null,
    threadHistory: null,
  })

  function composeNewEmail() {
    composeInput.value = {
      subjectLine: '',
      recipient: '',
      text: '',
      file: null,
      threadHistory: null,
    }
    isComposeWindowOpen.value = true
  }

  function composeReplyEmail(emailId: string) {
    const emailToReplyTo = getEmailById(emailId)

    if (!emailToReplyTo) {
      composeNewEmail()
      return
    }

    composeInput.value = {
      subjectLine: `Re: ${emailToReplyTo.subjectLine}`,
      recipient: emailToReplyTo.sender.fullName,
      text: '',
      file: null,
      threadHistory: emailToReplyTo,
    }

    isComposeWindowOpen.value = true
  }

  function closeComposeWindow() {
    isComposeWindowOpen.value = false
  }

  function getFileFromMockFile(
    email: PopulatedStockDirectEmail,
  ): Artefact | null {
    if (!email.mockFileName || !email.mockFileType) return null

    return {
      fileNameWithExtension: email.mockFileName,
      mimeType: getMimeTypeFromMockFileType(email.mockFileType),
      path: '',
    }
  }

  function processTimestamp(time: string, randomTime: Moment) {
    const now = moment().format('YYYY-MM-DD')
    const formattedTimestamp = moment(`${now} ${time || randomTime}`) as any

    // user may have entered the timestamp as "yesterday"
    if (
      typeof formattedTimestamp === 'string' &&
      formattedTimestamp.toLowerCase() === 'invalid date'
    ) {
      return time
    }
    return getShortEmailDateStringFromDate(randomTime)
  }

  function getEmailsFromStockDirectEmails(
    emails: PopulatedStockDirectEmail[],
  ): Email[] {
    const randomTimes = getArrayOfOffsetTimestamps(10, 240, emails.length)

    return emails
      .filter((stockEmail) => stockEmail.characters.length !== 0)
      .map((email, index) => {
        const timestamp = email.time
          ? email.time
          : processTimestamp(email.time, randomTimes[index])
        if (email.characters.length > 1) {
          const firstCharacter = email.characters.shift() as Character
          return {
            id: getNewId(),
            sender: firstCharacter,
            recipient: 'me',
            subjectLine: email.subjectLine,
            text: email.previewText,
            timestamp,
            displayIndex: email.displayIndex,
            isUnread: false,
            isInteractive: true,
            file: getFileFromMockFile(email),
            users: email.characters,
          }
        }

        return {
          id: getNewId(),
          sender: email.characters[0],
          recipient: 'me',
          subjectLine: email.subjectLine,
          text: email.previewText,
          timestamp,
          displayIndex: email.displayIndex,
          isUnread: false,
          isInteractive: false,
          file: getFileFromMockFile(email),
          users: [],
        }
      })
      .sort((a, b) => a.displayIndex - b.displayIndex)
  }

  function initEmailStore() {
    if (!interactionControllerStore.isInteractionReady) return

    $reset()

    if (!interactionControllerStore.simulation) return

    // Add stock emails
    mockEmails.value.push(
      ...getEmailsFromStockDirectEmails(
        interactionControllerStore.simulation.stockDirectEmails,
      ),
    )
  }

  const emailInteractionType = computed(() => {
    if (!interactionControllerStore.simulation) return null

    return interactionControllerStore.simulation.type
  })

  const isComposeButtonDisabled = computed(() => {
    if (!emailInteractionType.value) return true

    return emailInteractionType.value === SimulationType.emailReceive
  })

  async function startEmail() {
    setActualStartTime()

    const { error } = await interactionControllerStore.startInteraction()

    if (error !== null) {
      hasError.value = true
    }
  }

  async function continueEmail() {
    const { error } = await interactionControllerStore.continueInteraction()

    if (error !== null) {
      hasError.value = true
    }
  }
  async function sendCandidateEmail() {
    interactionControllerStore.addEmailMessageToThread({
      type: 'message',
      messageType: 'email',
      author: 'candidate',
      text: composeInput.value.text,
      file: composeInput.value.file,
      subject: composeInput.value.subjectLine,
      timestamp: getTimestamp(),
      displayIndex: 0, // Added missing property
    })

    closeComposeWindow()

    setActiveEmail(null)

    setPage('inbox')

    await continueEmail()
  }

  function $reset() {
    mockEmails.value = []
    interactiveEmails.value = []
    page.value = 'inbox'
    activeEmailId.value = null
    isComposeWindowOpen.value = false
    composeInput.value = {
      subjectLine: '',
      recipient: '',
      text: '',
      file: null,
      threadHistory: null,
    }
  }

  const momentStartTime = computed(() => {
    const now = moment().format('YYYY-MM-DD')
    return moment(`${now} ${startTime.value}`)
  })

  return {
    allEmails,
    inbox,
    sentEmails,
    inboxUnreadCount,
    sentEmailsUnreadCount,
    activeList,
    page,
    setPage,
    getEmailById,
    activeEmailId,
    activeEmail,
    setActiveEmail,
    isComposeWindowOpen,
    composeInput,
    composeNewEmail,
    composeReplyEmail,
    closeComposeWindow,
    initEmailStore,
    $reset,
    isComposeButtonDisabled,
    hasError,
    startEmail,
    continueEmail,
    sendCandidateEmail,
    skin,
    interactiveEmails,
    getOffsetTimestamp,
    interactionState,
    canCandidateCompose,
    mockEmails,
    startTime,
    momentStartTime,
  }
})
