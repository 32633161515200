import type { WithDisplayColor } from './Colors'
import { type DatabaseMetadata } from '~/types/DatabaseMetadata'
import { type WithAuthoringStatus } from '~/types/AuthoringStatus'
import { type WithDisplayIndex } from '~/types/DisplayIndex'
import { type NumberOrNull } from '~/types/NumberOrNull'
import type { UploadedFile } from '~/types/UploadedFile'
import { ExternalContentType } from '~/types/ExternalContentType'

export enum TestFormat {
  Likert = 'likert',
  Mcq = 'mcq',
  Written = 'written',
  Simulation = 'simulation',
}

export interface TestGradingCategoryLevel extends WithAuthoringStatus {
  id: string
  level: NumberOrNull
  requiredPercentage: NumberOrNull // this becomes user input
  // requiredPoints: NumberOrNull // this is FE-side computed: = pointsAvailableFromQuestions * (requiredPercentage / 100)
  title: string
  description: string
}

export interface TestGradingCategory
  extends WithAuthoringStatus,
    WithDisplayColor {
  id: string
  title: string
  levels: TestGradingCategoryLevel[]
  weighting: NumberOrNull
  maxAvailablePoints: number
}

export enum GradingType {
  Skills = 'skills',
  Competency = 'competency',
  Preference = 'preference',
  Knowledge = 'knowledge',
}

export enum McqChoiceType {
  Text = 'text',
  Image = 'image',
}

export interface Choice extends WithDisplayIndex {
  id: string
  text: string

  // MCQ
  correct: boolean
  imageUrl: string
  points: NumberOrNull

  // Likert
  value: NumberOrNull
}

export interface WrittenQuestionGradingCategoryLevel {
  gradingStatements: string[]
  description: string
  requiredPoints: NumberOrNull
  level: NumberOrNull
  id: string
}

export interface WrittenQuestionGradingCategory {
  testGradingCategoryId: string // GradingCategory.id
  levels: WrittenQuestionGradingCategoryLevel[]
}

// !!
export interface SimulationQuestionGradingCategoryLevel {
  description: string
  requiredPoints: NumberOrNull
}

// !!
export interface SimulationGradingCategory {
  testGradingCategoryId: string // GradingCategory.id
  levels: SimulationQuestionGradingCategoryLevel[]
}

export enum GradingContextType {
  ModelAnswer = 'model-answer',
}

export interface GradingContext {
  type: GradingContextType | null
  text: string
}

/**
 * **Confidence rating**
 * [incorrect, correct]
 */
export type ConfidenceRatingEntry = [NumberOrNull, NumberOrNull]

export interface ConfidenceRating
  extends Record<number, ConfidenceRatingEntry> {
  5: ConfidenceRatingEntry
  4: ConfidenceRatingEntry
  3: ConfidenceRatingEntry
  2: ConfidenceRatingEntry
  1: ConfidenceRatingEntry
}

// export interface QuestionGrading {
//   requiresGradingNote: boolean
//   gradingNoteInstructions: string
//   rubric: WrittenQuestionGradingCategory[] | SimulationGradingCategory[]
//   gradingContext: GradingContext
//   testGradingCategoryId: string | null
// }

export interface Question extends WithAuthoringStatus, WithDisplayIndex {
  id: string
  required: boolean
  heading: string | null
  text: string | null
  imageUrl: string | null
  externalContentUrl: string | null
  externalContentType: ExternalContentType | null

  // MCQ
  choiceType: McqChoiceType | null

  // Written & MCQ
  confidenceRating: ConfidenceRating

  // MCQ & Likert
  choices: Choice[]

  // Simulation
  simulationId: string | null

  requiresGradingNote: boolean
  gradingNoteInstructions: string
  rubric: WrittenQuestionGradingCategory[] | SimulationGradingCategory[]
  gradingContext: GradingContext
  testGradingCategoryId: string | null
}

export enum CategoryScoreAllocation {
  ActualUserScore = 'actual-user-score',
  CategoryLevelScore = 'category-level-score',
}

export enum CategoryScoreWeighting {
  Manual = 'manual',
  Points = 'points',
}

// export interface TestGrading {
//   categoryScoreAllocation: CategoryScoreAllocation | null
//   categoryScoreWeighting: CategoryScoreWeighting | null
//   rubric: TestGradingCategory[]
//   type: GradingType | null
//   maxAvailablePoints: number
// }

export enum TestType {
  TechnicalSkills = 'technical-skills',
  SoftSkills = 'soft-skills',
  HardSkills = 'hard-skills',
  Personality = 'personality',
  Custom = 'custom',
}

export interface Test extends DatabaseMetadata, WithAuthoringStatus {
  id: string
  format: TestFormat
  testType: TestType | null
  title: string
  isPublished: boolean
  heading: string
  requiresBlurReporting: boolean
  useTimeLimit: boolean
  durationMinutes: NumberOrNull
  introduction: string
  objective: string
  questions: Question[]
  uploadedFile: UploadedFile | null
  shouldRandomiseQuestionOrder: boolean
  forceFullScreen: boolean

  // MCQ ------------------------------
  hasConfidenceRating: boolean
  shouldRandomiseResponseOptions: boolean

  // Defaults ------------------------------
  choicesDefault: Omit<Choice, 'id'>[] | null
  confidenceRatingsDefault: ConfidenceRating | null

  // And of course
  primaryWorkspaceId: string
  workspaces: string[]
  isGlobal: boolean

  categoryScoreAllocation: CategoryScoreAllocation | null
  categoryScoreWeighting: CategoryScoreWeighting | null
  rubric: TestGradingCategory[]
  gradingType: GradingType | null
  maxAvailablePoints: number
}
