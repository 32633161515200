export enum ColorOption {
  Pink = 'pink',
  Red = 'red',
  Orange = 'orange',
  Yellow = 'yellow',
  Green = 'green',
  Teal = 'teal',
  Sky = 'sky',
  Indigo = 'indigo',
  Purple = 'purple',
  Primary = 'primary',
  Error = 'error',
  Success = 'success',
  Grey = 'grey',
}

export interface WithDisplayColor {
  displayColor: ColorOption
}
