export default {
  root: {
    class: [
      // Sizing and Shape
      'min-w-[12rem]',
      'rounded-md',
      // Spacing
      'p-1',
      // Colors
      'bg-surface-0 dark:bg-surface-700',
      'text-surface-700 dark:text-white/80',
      'border border-surface-200 dark:border-surface-700',
      'shadow-md',
    ],
  },
  menu: {
    class: [
      // Spacings and Shape
      'list-none',
      'm-0',
      'p-0',
      'outline-none',
    ],
  },
  menuitem: {
    class: [
      // Position
      'relative',
    ],
  },
  content: ({ context }) => ({
    class: [
      // Shape
      'rounded-md',

      //  Colors
      {
        'text-surface-500 dark:text-white/70':
          !context.focused && !context.active,
        'text-surface-500 dark:text-white/70 bg-surface-200 dark:bg-surface-600/90':
          context.focused && !context.active,
        'text-primary-700 dark:text-surface-0/80 bg-primary-50 dark:bg-primary-400/30':
          context.focused && context.active,
        'text-primary-700 dark:text-surface-0/80 bg-surface-200 dark:bg-primary-400/30':
          !context.focused && context.active,
      },

      // Hover States
      {
        'hover:bg-surface-100 dark:hover:bg-surface-600/80 hover:text-surface-700 dark:hover:text-white/80':
          !context.active,
        'hover:bg-surface-200 dark:hover:bg-primary-300/30 hover:text-surface-700 dark:hover:text-white/80':
          context.active,
      },

      // Transitions
      'transition-shadow',
      'duration-200',
    ],
  }),
  action: {
    class: [
      'relative',
      // Flexbox

      'flex',
      'items-center',

      // Spacing
      'py-3',
      'px-5',

      // Color
      'text-surface-700 dark:text-white/80',

      // Misc
      'no-underline',
      'overflow-hidden',
      'cursor-pointer',
      'select-none',
    ],
  },
  icon: {
    class: [
      // Spacing
      'mr-2',

      // Color
      'text-surface-600 dark:text-white/70',
    ],
  },
  label: {
    class: ['leading-none'],
  },
  submenuicon: {
    class: [
      // Position
      'ml-auto',
    ],
  },
  submenu: {
    class: [
      // Size
      'w-full sm:min-w-[12rem] sm:w-fit',

      // Spacing
      'p-1',
      'm-0',
      'list-none',

      // Shape
      'rounded-md',
      'sm:border border-surface-200 dark:border-surface-700',
      'shadow-none sm:shadow-md',

      // Position
      'static sm:absolute',
      'z-10',

      // Color
      'bg-surface-0 dark:bg-surface-700',
      'overflow-hidden',
    ],
  },
  separator: {
    class: 'border-t border-surface-200 dark:border-surface-600 my-1',
  },
}
