export class BaseError extends Error {
  timestamp: string | number
  error: string
  constructor(message: string, error: string, timestamp: string | number) {
    super(message)
    this.name = 'BaseError'
    this.timestamp = timestamp || new Date().toISOString()
    this.error = error
  }
}
