import type { Candidate } from './Candidate'
import type { Unsaved } from './Unsaved'

export interface ThreadEntryBase {
  type: 'log' | 'message'
  timestamp: string
}

// MESSAGES ====================================
export interface Message extends ThreadEntryBase {
  type: 'message'
  author: 'candidate' | 'character'
  messageType: string
}

export interface TextMessage extends Message {
  messageType: 'text'
  text: string
}

export interface File {
  path: string
  fileNameWithExtension: string
  mimeType: string
}

export interface FileMessage extends Message {
  messageType: 'file'
  file: File
}

export interface EmailMessage extends Message {
  messageType: 'email'
  text: string
  subject: string
  file: File | null
  displayIndex: number
}

export type AnyMessage = TextMessage | FileMessage | EmailMessage

// LOGS ====================================
export interface Log extends ThreadEntryBase {
  type: 'log'
  logType: string
  event: string
}

// INTERACTION LOG ====================================
export interface InteractionLog extends Log {
  logType: 'interaction'
  event: 'start' | 'end'
}

// AGENDA LOG ====================================
export interface AgendaLog extends Log {
  logType: 'agenda'
}

export interface AgendaStateLog extends AgendaLog {
  event: 'state-update'
  currentAgendaItemId: string
  currentAgendaItemStatus: 'in progress' | 'complete'
}

export interface AgendaSuccessStateLog extends AgendaLog {
  event: 'success-assessment'
  agendaItemId: string
  successfulStateDescription: string
  isSuccessful: boolean
}

export interface AgendaNavigationLog extends AgendaLog {
  event: 'agenda-navigation'
  currentAgendaItemId: string
  nextAgendaItemId: boolean
}

export interface AgendaRetryLog extends AgendaLog {
  event: 'agenda-retry'
  agendaItemId: string
  retryCount: number
}

// CHARACTER LOG ====================================
export interface CharacterLog extends Log {
  logType: 'character'
}

export interface CharacterMessageLog extends CharacterLog {
  event: 'character-message-generation'
  instruction: string
}

export type AnyLog =
  | InteractionLog
  | AgendaStateLog
  | AgendaSuccessStateLog
  | AgendaNavigationLog
  | AgendaRetryLog
  | CharacterMessageLog

export type ThreadEntry = AnyMessage | AnyLog

export type Thread = ThreadEntry[]

export interface Interaction {
  id: string
  isPreview: boolean
  thread: Thread
  simulationId: string
  createdAt: string
  updatedAt: string
  candidateId?: string
  isReady: boolean
  isComplete?: boolean
}

export interface PostInteractionBody {
  isPreview: boolean
  candidate?: Unsaved<Candidate>
}

export enum InteractionState {
  NotStarted = 'not-started',
  InProgress = 'in-progress',
  Complete = 'complete',
  Closed = 'closed',
  NoRetry = 'no-retry',
}

export interface PopulatedInteraction extends Interaction {
  candidate: Candidate
}

export interface MaybePopulatedInteraction extends Interaction {
  candidate?: Candidate
}

export enum InteractionBackground {
  Gradients = 'gradients',
  Flower = 'flower',
  Galaxy = 'galaxy',
  Waves = 'waves',
  Rays = 'rays',
  Mountain = 'mountain',
}

export type InteractionWithoutThread = Omit<Interaction, 'thread'>

export type MaybePopulatedInteractionWithoutThread = Omit<
  MaybePopulatedInteraction,
  'thread'
>

export interface InteractionSummary {
  total: number
  lastDay: number
  lastHour: number
  recent: InteractionWithoutThread[]
}
