<template>
  <Dialog
    v-model:visible="isDialogVisible"
    modal
    block-scroll
    header="Header"
    :style="{
      width: 'auto',
      minWidth: '25rem',
      maxWidth: '35rem',
      overflow: 'hidden',
    }"
  >
    <template #container>
      <div class="flex flex-col gap-4 bg-white p-5 overflow-hidden">
        <!-- HEADER -->
        <div class="flex w-full items-center justify-between">
          <h1 class="text-lg font-semibold">{{ header }}</h1>
          <font-awesome-icon
            v-if="isClosable"
            :icon="['far', 'xmark']"
            class="w-4 cursor-pointer duration-100 dark:text-surface-400 hover:text-surface-700"
            @click="$emit('click:close')"
          />
        </div>

        <!-- MESSAGE -->
        <p
          v-if="message"
          class="text-2xs font-regular text-surface-700"
        >
          {{ message }}
        </p>

        <slot name="default" />

        <!-- ACTIONS -->
        <div class="flex items-center justify-end gap-2 mt-2">
          <slot
            name="actions"
            :close-dialog="closeDialog"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  header: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: false,
    default: undefined,
  },
  isClosable: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const emit = defineEmits(['update:modelValue', 'click:close'])

const isDialogVisible = computed({
  get() {
    return props.modelValue
  },
  set(value: boolean) {
    emit('update:modelValue', value)
  },
})

function closeDialog() {
  isDialogVisible.value = false
}
</script>
