<template>
  <AppDialog
    v-model="isDialogOpen"
    header="Get Simulation Link"
    style="width: 35rem"
    @click:close="handleClose"
  >
    <AppStepperSimple
      v-model="stepIndex"
      :steps="['candidate', 'tests', 'assessments', 'link']"
      class="flex flex-col gap-4"
    >
      <template #step-1>
        <span class="text-xs text-surface-700"
          >Select a candidate loading strategy</span
        >
        <div class="grid grid-cols-2 gap-2">
          <DialogSimulationLinkOption
            label="Iframe"
            icon="fa-regular fa-code"
            @click="setCandidateStrategy('iframe')"
          />
          <DialogSimulationLinkOption
            label="Prompt Candidate"
            icon="fa-regular fa-window-maximize"
            @click="setCandidateStrategy('promptCandidate')"
          />
          <DialogSimulationLinkOption
            label="Demo"
            icon="fa-regular fa-circle-play"
            @click="setCandidateStrategy('demo')"
          />
        </div>
      </template>

      <template #step-2>
        <span class="text-xs text-surface-700"
          >Select a test to link the simulation to (optional)</span
        >
        <div class="grid grid-cols-2 gap-2">
          <DialogSimulationLinkOption
            label="No test link"
            @click="setConnectedTestId(null)"
          />
          <DialogSimulationLinkOption
            v-for="test in connectedTests"
            :key="test.id"
            :label="test.title"
            @click="setConnectedTestId(test.id)"
          />
        </div>
      </template>

      <template #step-3>
        <span class="text-xs text-surface-700"
          >Select an assessment to link the test to (optional)</span
        >
        <div class="grid grid-cols-2 gap-2">
          <DialogTestLinkOption
            label="No assessment link"
            @click="setConnectedAssessmentId(null)"
          />
          <DialogTestLinkOption
            v-for="assessment in connectedAssessments"
            :key="assessment.id"
            :label="assessment.title"
            @click="setConnectedAssessmentId(assessment.id)"
          />
        </div>
      </template>

      <template #step-4>
        <span class="text-xs text-surface-700"
          >The simulation can be accessed from the link below</span
        >
        <AppInputText
          v-model="simulationLink"
          :append-icon="copyIcon"
          :is-append-icon-button="true"
          readonly
          @click-append-icon="handleClickCopy"
        />
      </template>
    </AppStepperSimple>
    <template #actions>
      <AppButtonSecondary
        v-if="stepIndex > 0"
        label="Back"
        @click="stepIndex--"
      />
      <AppButtonPrimary
        label="Close"
        @click="handleConfirm"
      />
    </template>
  </AppDialog>
</template>

<script setup lang="ts">
import { useTestStore } from '@/stores/test'
import { useAssessmentStore } from '@/stores/assessment'
import getFullClientPath from '@/utils/getFullClientPath'

const testStore = useTestStore()
const assessmentStore = useAssessmentStore()

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  simulationId: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue', 'resolve', 'close'])

const isDialogOpen = computed({
  get() {
    return props.modelValue
  },
  set(value: boolean) {
    emit('update:modelValue', value)
  },
})

const simulationLink = computed(() => {
  const baseUrl = getFullClientPath(`simulation/${props.simulationId}`)
  const params = new URLSearchParams()

  // CANDIDATE
  if (candidateStrategy.value === 'promptCandidate') {
    params.set('manual_candidate', 'true')
  } else if (candidateStrategy.value === 'demo') {
    params.set('demo', 'true')
  }

  // TEST
  if (connectedTestId.value) {
    params.set('test_id', connectedTestId.value)
  }

  // ASSESSMENT
  if (connectedAssessmentId.value) {
    params.set('assessment_id', connectedAssessmentId.value)
  }

  return params.toString() ? `${baseUrl}?${params.toString()}` : baseUrl
})

function handleClose() {
  emit('close')
  isDialogOpen.value = false
}

function handleConfirm() {
  emit('resolve')
  isDialogOpen.value = false
}

type CandidateStrategy = 'iframe' | 'promptCandidate' | 'demo'

const candidateStrategy = ref<CandidateStrategy | null>(null)

const connectedTestId = ref<string | null>(null)

const connectedTests = computed(() =>
  testStore.getTestsBySimulationId(props.simulationId),
)

const connectedAssessmentId = ref<string | null>(null)

const connectedAssessments = computed(() =>
  connectedTestId.value
    ? assessmentStore.getAssessmentsByTestId(connectedTestId.value)
    : [],
)

const stepIndex = ref(0)

function nextStep() {
  stepIndex.value++
}

function setCandidateStrategy(strategy: CandidateStrategy) {
  candidateStrategy.value = strategy
  nextStep()
}

function setConnectedTestId(testId: string | null) {
  connectedTestId.value = testId
  nextStep()
}

function setConnectedAssessmentId(assessmentId: string | null) {
  connectedAssessmentId.value = assessmentId
  nextStep()
}

// COPY LINK ================================================================================================
const copyIcon = ref('fa-regular fa-clone')

async function handleClickCopy() {
  await navigator.clipboard.writeText(simulationLink.value)
  copyIcon.value = 'fa-regular fa-check'

  setTimeout(() => {
    copyIcon.value = 'fa-regular fa-clone'
  }, 3000)
}
</script>
