<template>
  <AppDialog
    v-model="isDialogVisible"
    header="Test Setup Incomplete"
    @click:close="emit('close')"
  >
    <div class="text-2xs text-surface-700 font-normal">
      <p>In order to publish the test, the following is still required:</p>
      <br />
      <ul class="list-disc">
        <li
          v-for="(message, index) in validationMessages"
          :key="index"
          class="list-item list-inside"
        >
          {{ message }}
        </li>
      </ul>
      <br />
      <p>
        Would you like to continue editing the test or save the test as a draft?
      </p>
    </div>
    <template #actions>
      <AppButtonSecondary
        label="Continue Editing"
        @click="$emit('resolve', false)"
      />
      <AppButtonPrimary
        label="Save as Draft"
        autofocus
        @click="$emit('resolve', true)"
      />
    </template>
  </AppDialog>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  validationMessages: {
    type: Array as PropType<string[]>,
    required: true,
  },
})

const isDialogVisible = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const emit = defineEmits(['update:modelValue', 'resolve', 'close'])
</script>
