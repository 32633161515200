<template>
  <Transition
    name="global_fade"
    mode="out-in"
  >
    <slot />
  </Transition>
</template>

<script setup lang="ts">
const props = defineProps({
  duration: {
    type: String,
    required: false,
    default: '0.5',
  },
})

const durationValue = computed(() => {
  if (props.duration.includes('s')) return props.duration

  return `${props.duration}s`
})
</script>

<style>
.global_fade-enter-from,
.global_fade-leave-to {
  opacity: 0;
}

.global_fade-enter-active,
.global_fade-leave-active {
  transition: opacity v-bind(durationValue);
}

.global_fade-enter-to,
.global_fade-leave-from {
  opacity: 1;
}
</style>
