<template>
  <AppDialog
    v-model="isDialogOpen"
    header="Enter Details"
    message="To continue, please enter your details below:"
    :is-closable="false"
  >
    <div class="flex flex-col gap-3 w-full">
      <!-- EMAIL -->
      <AppInputLabel
        label="Email"
        for-id="email"
      >
        <AppInputText
          id="email"
          v-model="candidateData.identifier"
          placeholder="jane@example.com"
          autofocus
          class="w-full"
        />
      </AppInputLabel>
      <!-- FIRST NAME -->
      <AppInputLabel
        label="First name"
        for-id="first-name"
      >
        <AppInputText
          id="first-name"
          v-model="candidateData.firstName"
          placeholder="Jane"
          class="w-full"
        />
      </AppInputLabel>
      <!-- LAST NAME -->
      <AppInputLabel
        label="Last name"
        for-id="last-name"
      >
        <AppInputText
          id="last-name"
          v-model="candidateData.lastName"
          placeholder="Doe"
          class="w-full"
        />
      </AppInputLabel>
    </div>

    <template #actions>
      <AppButtonPrimary
        :disabled="!isFormValid"
        label="Continue"
        @click="handleConfirm"
      />
    </template>
  </AppDialog>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue', 'resolve', 'close'])

const isDialogOpen = computed({
  get() {
    return props.modelValue
  },
  set(value: boolean) {
    emit('update:modelValue', value)
  },
})

const candidateData = ref({
  identifier: '',
  firstName: '',
  lastName: '',
  organization: 'manual',
})

function handleConfirm() {
  emit('resolve', candidateData.value)
  isDialogOpen.value = false
}

const isFormValid = computed(() => {
  return (
    candidateData.value.firstName &&
    candidateData.value.lastName &&
    candidateData.value.identifier
  )
})
</script>
