export function useLatestUpdatedAtTimestamp<T extends { updatedAt: string }>(
  array: Ref<T[]> | ComputedRef<T[]>,
) {
  return computed(() => {
    const defaultDate = new Date('1970-01-01T00:00:00Z').toISOString()

    if (array.value.length === 0) return defaultDate

    return array.value.reduce<undefined | string>((latest, item) => {
      if (!latest) return item.updatedAt

      if (new Date(item.updatedAt) > new Date(latest)) {
        return item.updatedAt
      }

      return latest
    }, defaultDate)
  })
}
