export enum AuthoringStatus {
  Draft = 'draft',
  Ready = 'ready',
}

export interface WithAuthoringStatus {
  authoringStatus: AuthoringStatus
}

export interface AuthoringStatusCounts {
  live: number
  draft: number
  unpublished: number
}
