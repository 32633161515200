import type { Unsaved } from '~/types/Unsaved'
import type { Workspace } from '~/types/Workspace'

export const workspaceDefault: Required<Unsaved<Workspace>> = {
  exclusions: [],
  frequentlyUsedTerms: [],
  industryDescription: '',
  title: '',
  values: [],
  workingAtmosphere: '',
  createdBy: '',
  profileImageUrl: null,
}
