import type { Artefact } from './Artefact'
import type { AuthoringStatus } from './AuthoringStatus'
import type { Character } from './Character'
import type { DatabaseMetadata } from './DatabaseMetadata'
import type { Environment } from './Environment'
import type { GlobalEntityData } from './GlobalEntityData'

export enum ConversationTone {
  Polite = 'Polite',
  Frustrated = 'Frustrated',
  Serious = 'Serious',
  Casual = 'Casual',
  Assertive = 'Assertive',
  Enthusiastic = 'Enthusiastic',
  Concise = 'Concise',
  Formal = 'Formal',
  Professional = 'Professional',
}

export interface EmailToCandidate {
  artefact: Artefact | null
  body: string
  subject: string
  type: 'received'
}

export interface Outcome {
  artefact: Artefact | null
  chatInstructionOrExactDialog: string
  isDefault: boolean
  isSuccessful: boolean
  useExactDialog: boolean
  title: string
  id: string
}

export interface StockDirectChat {
  id: string
  characters: string[] // Character ids
  isOnline: boolean
  previewText: string
}

export interface PopulatedStockDirectChat
  extends Omit<StockDirectChat, 'characters'> {
  characters: Character[]
}

export enum MockFileType {
  Document = 'Document',
  Image = 'Image',
  Pdf = 'Pdf',
  Slideshow = 'Slideshow',
  Video = 'Video',
}

export interface StockDirectEmail {
  id: string
  characters: string[] // Character ids
  previewText: string
  subjectLine: string
  mockFileName: string
  mockFileType: MockFileType | null
  time: string
  displayIndex: number
}

export interface PopulatedStockDirectEmail
  extends Omit<StockDirectEmail, 'characters'> {
  characters: Character[]
}

export interface PopulatedStockDirectEmailWithTime
  extends PopulatedStockDirectEmail {
  time: string
}

export enum SimulationType {
  emailReceive = 'Email receive and reply',
  emailCreate = 'Email create and send',
  chatConversation = 'Chat conversation',
}

export enum NavigationType {
  Agenda = 'Agenda',
  Outcome = 'Outcome',
}

export interface Navigation {
  type: NavigationType | null
  destination: string // agenda item id or outcome id
}

export interface AgendaAction {
  chatInstructionOrExactDialog: string
  useExactDialog: boolean
  artefact: Artefact | null
}

export interface AgendaActionWithNavigation extends AgendaAction {
  navigation: Navigation
}

export enum Logic {
  If = 'If',
  IfElse = 'If-Else',
}

export interface AgendaItem {
  id: string
  mainAction: AgendaAction
  ifAction: AgendaActionWithNavigation
  elseAction: AgendaActionWithNavigation
  logic: Logic | null
  order: number
  successfulStateDescription: string
  title: string
  maxRetries: number
}

export enum ChatSkin {
  Google = 'google',
  Slack = 'slack',
}

export enum EmailSkin {
  Google = 'google',
  Microsoft = 'microsoft',
}

export enum SimulationBackgroundOption {
  None = 'none',
  Gradients = 'gradients',
  Flower = 'flower',
  Galaxy = 'galaxy',
  Waves = 'waves',
  Rays = 'rays',
  Mountain = 'mountain',
}

export interface Simulation extends DatabaseMetadata, GlobalEntityData {
  background: SimulationBackgroundOption | null
  createdBy: string
  canBeRetried: boolean
  characterId: string | null // character id
  chatAgenda: AgendaItem[]
  chatSkin: ChatSkin
  emailSkin: EmailSkin
  conversationTone: ConversationTone | null
  emailToCandidate: EmailToCandidate
  emailSuccessfulStateDescription: string
  environmentId: string | null
  tags: string[]
  outcomes: Outcome[]
  purpose: string
  startTime: string // 24hr time eg 14:32 <-- FE validates
  authoringStatus: AuthoringStatus
  isPublished: boolean
  stockDirectChats: StockDirectChat[]
  stockDirectEmails: StockDirectEmail[]
  type: SimulationType | null
  title: string
  candidateObjective: string
}

export interface PopulatedSimulation
  extends Omit<Simulation, 'stockDirectChats' | 'stockDirectEmails'> {
  character: Character
  stockDirectChats: PopulatedStockDirectChat[]
  stockDirectEmails: PopulatedStockDirectEmail[]
  environment: Environment
}
