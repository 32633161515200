const isOnline = useOnline()

watch(isOnline, (online) => {
  if (online) {
    console.info('Browser online')
  } else {
    console.info('Browser offline')
  }
})

export function useOnlineStatus() {
  return isOnline
}
