<!-- PROPS: `modelValue: number` (step index 0), `steps: StepName[]`
SLOTS: `step-{stepNumber}` (one-indexed), `actions` provides nextStep & prevStep methods -->
<template>
  <div class="">
    <template
      v-for="(step, index) in steps"
      :key="step"
    >
      <slot
        v-if="index == stepIndex"
        :name="`step-${index + 1}`"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  steps: {
    type: Array as PropType<string[]>,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue'])

const stepIndex = computed<number>({
  get() {
    return props.modelValue
  },
  set(value: number) {
    emit('update:modelValue', value)
  },
})
</script>
