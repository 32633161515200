import validate from "/home/runner/work/studio-web-app/studio-web-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/runner/work/studio-web-app/studio-web-app/middleware/auth.global.ts";
import feat_45global from "/home/runner/work/studio-web-app/studio-web-app/middleware/feat.global.ts";
import user_45role_45global from "/home/runner/work/studio-web-app/studio-web-app/middleware/user-role.global.ts";
import manifest_45route_45rule from "/home/runner/work/studio-web-app/studio-web-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  feat_45global,
  user_45role_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}