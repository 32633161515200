import { httpService } from '~/services/httpService'
import type { ShareableEntityList } from '~/types/ShareableEntityList'
import { type PopulatedSimulation, type Simulation } from '~/types/Simulation'

export function simulationService() {
  const config = useRuntimeConfig()
  const serverUrl = config.public.serverUrl

  const { getResource, deleteResource, postResource, patchResource } =
    httpService<Simulation>(serverUrl)

  async function getSimulations(queryParameters: Record<string, string> = {}) {
    const response = await getResource<Simulation[]>('/simulations', {
      query: queryParameters,
    })
    return response
  }

  async function getWorkspaceSimulations(workspaceId: string) {
    const response = await getResource<ShareableEntityList<Simulation>>(
      `/workspaces/${workspaceId}/simulations`,
    )
    return response
  }

  async function getSimulationById(simulationId: string) {
    const response = await getResource(`simulations/${simulationId}`)
    return response
  }

  async function getPopulatedSimulationById(simulationId: string) {
    const response = await getResource<PopulatedSimulation>(
      `simulations/${simulationId}`,
      {
        query: {
          populate:
            'character,stockDirectEmails.characters,stockDirectChats.characters,environment',
        },
        useFallbackAuth: true,
      },
    )
    return response
  }

  async function postSimulation(body: Partial<Simulation>) {
    const response = await postResource(`/simulations`, body)
    return response
  }

  async function updateSimulationById(
    simulationId: string,
    body: Partial<Simulation>,
  ) {
    const response = await patchResource(`/simulations/${simulationId}`, body)
    return response
  }

  async function deleteSimulationById(simulationId: string) {
    const response = await deleteResource(`/simulations/${simulationId}`)
    return response
  }

  async function publishSimulationById(simulationId: string) {
    const response = await postResource(
      `/simulations/${simulationId}/publish`,
      {},
    )
    return response
  }

  async function unpublishSimulationById(simulationId: string) {
    const response = await postResource(
      `/simulations/${simulationId}/unpublish`,
      {},
    )
    return response
  }

  async function getWorkspaceSimulationUpdates(
    workspaceId: string,
    updatedAtGt: string,
  ) {
    const response = await getResource<ShareableEntityList<Simulation>>(
      `/workspaces/${workspaceId}/simulations`,
      {
        query: {
          updatedAtGt,
        },
      },
    )
    return response
  }

  return {
    getSimulations,
    getWorkspaceSimulations,
    getSimulationById,
    getPopulatedSimulationById,
    deleteSimulationById,
    postSimulation,
    updateSimulationById,
    publishSimulationById,
    unpublishSimulationById,
    getWorkspaceSimulationUpdates,
  }
}
