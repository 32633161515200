import { acceptHMRUpdate, defineStore } from 'pinia'
import type { Artefact } from '~/types/Artefact'
import { type Chat } from '~/types/Chat'
import { useInteractionControlStore } from '~/stores/interactionControl'
import { type PopulatedStockDirectChat, ChatSkin } from '~/types/Simulation'
import { InteractionState } from '~/types/Interaction'

export const useChatStore = defineStore('chat', () => {
  // OTHER STORES ====================================
  const interactionControllerStore = useInteractionControlStore()

  // VIEW STATE ====================================
  // In future, this will be a computed value based on the simulation
  // const skin = computed(() => {
  //   if (!interactionControllerStore.simulation) return ChatSkin.Google

  //   return interactionControllerStore.simulation.chatSkin ?? ChatSkin.Google
  // })
  // For demo purposes
  const skin = ref<ChatSkin>(ChatSkin.Google)

  const interactionState = computed(
    () => interactionControllerStore.interactionState,
  )
  const environment = computed(() => interactionControllerStore.environment)
  const workplaceName = computed(() => environment.value?.workplaceName ?? '')
  const workplaceLogoUrl = computed(
    () => environment.value?.workplaceLogoUrl ?? null,
  )
  // Chats
  const chats = ref<Chat[]>([])
  const primaryChatId = ref<string | null>(null)

  // Active chat
  const activeChatId = ref<string | null>(null)
  const activeChat = computed(() => {
    if (!activeChatId.value) return null

    return chats.value.find((chat) => chat.id === activeChatId.value) ?? null
  })
  function setActiveChatId(id: string | null) {
    activeChatId.value = id
  }

  // Input fields
  const messageText = ref('')
  const isCharacterTyping = ref(false)
  const candidateArtefact = ref<Artefact | null>(null)

  function removeCandidateArtefact() {
    candidateArtefact.value = null
  }

  const hasError = ref(false)

  watch(
    () => messageText.value,
    () => {
      markPrimaryChatAsRead()
    },
  )

  // INTERACTION STATE ====================================
  const isSendButtonDisabled = computed(() => {
    if (interactionState.value !== InteractionState.InProgress) return true

    if (messageText.value === '' && !candidateArtefact.value) return true

    return isCharacterTyping.value
  })

  function markPrimaryChatAsRead() {
    if (!primaryChatId.value) return

    const primaryChat = chats.value.find(
      (chat) => chat.id === primaryChatId.value,
    )

    if (!primaryChat) return

    primaryChat.numberOfUnreadMessages = 0
  }

  function initChatStore() {
    if (!interactionControllerStore.isInteractionReady) return

    $reset()

    // Add stock chats
    interactionControllerStore.simulation?.stockDirectChats
      .filter((stockChat) => stockChat.characters.length !== 0)
      .forEach((chat) => {
        chats.value.push(getChatFromStockDirectChat(chat))
      })

    // Temp
    skin.value =
      interactionControllerStore.simulation?.chatSkin ?? ChatSkin.Google

    // Add primary chat
    chats.value.unshift(getPrimaryChat())
  }

  function updatePrimaryChatMessages() {
    const primaryChat = chats.value.find(
      (chat) => chat.id === primaryChatId.value,
    )

    if (!primaryChat) return

    primaryChat.messages = interactionControllerStore.thread

    primaryChat.numberOfUnreadMessages += 1
  }

  function getPrimaryChat(): Chat {
    primaryChatId.value = getNewId()
    return {
      id: primaryChatId.value,
      isInteractive: true,
      isOnline: true,
      users: [interactionControllerStore.simulation.character],
      messages: [],
      numberOfUnreadMessages: 0,
    }
  }

  function getChatFromStockDirectChat(
    stockChat: PopulatedStockDirectChat,
  ): Chat {
    return {
      id: getNewId(),
      isInteractive: false,
      isOnline: stockChat.isOnline,
      users: stockChat.characters,
      numberOfUnreadMessages: 0,
      messages: [
        {
          type: 'message',
          messageType: 'text',
          author: 'character',
          text: stockChat.previewText,
          timestamp: getTimestamp(),
        },
      ],
    }
  }

  async function startChat() {
    const { error } = await interactionControllerStore.startInteraction()

    if (error !== null) {
      hasError.value = true
      return
    }

    updatePrimaryChatMessages()
  }

  async function continueChat() {
    const { error } = await interactionControllerStore.continueInteraction()

    if (error !== null) {
      hasError.value = true
      return
    }

    updatePrimaryChatMessages()
  }

  async function sendCandidateMessage() {
    if (messageText.value === '' && !candidateArtefact.value) return

    isCharacterTyping.value = true

    // Add text message to thread
    if (messageText.value !== '') {
      interactionControllerStore.addTextMessageToThread({
        type: 'message',
        messageType: 'text',
        author: 'candidate',
        text: messageText.value,
        timestamp: getTimestamp(),
      })
    }

    if (candidateArtefact.value !== null) {
      interactionControllerStore.addFileMessageToThread({
        type: 'message',
        messageType: 'file',
        file: candidateArtefact.value,
        author: 'candidate',
        timestamp: getTimestamp(),
      })
    }

    messageText.value = ''
    candidateArtefact.value = null

    await continueChat()

    isCharacterTyping.value = false
  }

  // META ====================================
  function $reset() {
    chats.value = []
    activeChatId.value = null
    messageText.value = ''
    candidateArtefact.value = null
    hasError.value = false
  }

  return {
    skin,
    chats,
    activeChatId,
    activeChat,
    messageText,
    candidateArtefact,
    setActiveChatId,
    $reset,
    startChat,
    continueChat,
    sendCandidateMessage,
    initChatStore,
    markPrimaryChatAsRead,
    isCharacterTyping,
    hasError,
    interactionState,
    removeCandidateArtefact,
    isSendButtonDisabled,
    workplaceName,
    workplaceLogoUrl,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChatStore, import.meta.hot))
}
