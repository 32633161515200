<template>
  <template
    v-for="[dialogId] in Array.from(dialogs)"
    :key="dialogId"
  >
    <DialogComponentResolver :dialog-id="dialogId" />
  </template>
</template>

<script setup lang="ts">
const { dialogs } = useDialogService()
</script>
