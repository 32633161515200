import { useUserStore } from '~/stores/user'
import { UserRole } from '~/types/User'

export default defineNuxtRouteMiddleware((to) => {
  const userStore = useUserStore()

  if (
    typeof to.name === 'string' &&
    to.name.includes('system-admin') &&
    userStore.userRole !== UserRole.Super
  ) {
    return navigateTo('/')
  }
})
