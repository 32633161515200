import type { UseFetchOptions } from 'nuxt/app'
import * as Sentry from '@sentry/vue'
import * as jose from 'jose'
import { useAuthStore } from '~/stores/auth'
import { NetworkError } from '~/errors/NetworkError'
import type { AnyError, UseFetchError } from '~/types/Error'

type UseFetchWrapperReturnType<ResT> = Promise<
  { data: ResT; error: null } | { data: null; error: AnyError }
>

interface UseFetchWithCustomOptions<ResT> extends UseFetchOptions<ResT> {
  useFallbackAuth?: boolean
}

async function useFetchWrapper<ResT>(
  url: string,
  _options: UseFetchWithCustomOptions<ResT>,
): UseFetchWrapperReturnType<ResT> {
  // AUTH
  const authStore = useAuthStore()
  const config = useRuntimeConfig()

  async function getAuthHeader() {
    if (!authStore.isAuthenticated && _options.useFallbackAuth) {
      const secret = new TextEncoder().encode(
        config.public.mimiclyInterimSecretKey,
      )

      const token = await new jose.SignJWT({
        accessToken: config.public.mimiclyInterimAccessToken,
      })
        .setProtectedHeader({ alg: 'HS256' })
        .setIssuedAt()
        .setExpirationTime('1h')
        .sign(secret)

      return {
        headers: {
          fallbackAuthToken: token,
        },
      }
    }
    if (!authStore.isAuthenticated) {
      console.error('User is not authenticated')
      return {}
    }

    return {
      headers: {
        Authorization: `Bearer ${await authStore.getToken()}`,
      },
    }
  }

  const authHeader = await getAuthHeader()

  const options: UseFetchWithCustomOptions<ResT> = {
    ..._options,
    ...authHeader,
    watch: false,
    key: getNewId(),
  }

  try {
    const { data, error } = await useFetch<ResT, UseFetchError>(url, options)

    if (error.value !== null) {
      const networkError = NetworkError.fromUseFetchError(error.value)
      Sentry.captureException(networkError)

      return {
        data: null,
        error: networkError,
      }
    }

    return {
      data: data.value as ResT,
      error: null,
    }
  } catch (e: unknown) {
    const message =
      e instanceof Error
        ? e.message
        : typeof e === 'string'
          ? e
          : 'Unknown error'

    const error = new Error(message)

    Sentry.captureException(error)

    return {
      data: null,
      error,
    }
  }
}

export function httpService<ResT>(baseUrl: string, options?: any) {
  const optionsWithDefaults: UseFetchOptions<ResT> = {
    baseURL: baseUrl,
    server: false,
    watch: false,
    ...options,
  }

  async function getResource<GetResT = ResT>(url: string, options?: any) {
    const getOptionsWithDefaults: UseFetchOptions<GetResT> = {
      ...optionsWithDefaults,
      ...options,
    }

    return await useFetchWrapper<GetResT>(url, getOptionsWithDefaults)
  }

  async function postResource<PostResT = ResT, PostReqBodyT = PostResT>(
    url: string,
    body: Partial<PostReqBodyT>,
    options?: any,
  ) {
    const postOptionsWithDefaults: UseFetchOptions<PostResT> = {
      method: 'POST',
      ...optionsWithDefaults,
      ...options,
      ...{ body },
    }

    return await useFetchWrapper<PostResT>(url, postOptionsWithDefaults)
  }

  async function putResource<PutResT = ResT, PutReqBodyT = PutResT>(
    url: string,
    body: Partial<PutReqBodyT>,
    options?: any,
  ) {
    const putOptionsWithDefaults: UseFetchOptions<PutResT> = {
      method: 'PUT',
      ...optionsWithDefaults,
      ...options,
      ...{ body },
    }

    return await useFetchWrapper<PutResT>(url, putOptionsWithDefaults)
  }

  async function patchResource<PatchResT = ResT, PatchReqBodyT = PatchResT>(
    url: string,
    body: Partial<PatchReqBodyT>,
    options?: any,
  ) {
    const patchOptionsWithDefaults: UseFetchOptions<PatchResT> = {
      method: 'PATCH',
      ...optionsWithDefaults,
      ...options,
      ...{ body },
    }

    return await useFetchWrapper<PatchResT>(url, patchOptionsWithDefaults)
  }

  async function deleteResource<DeleteResT = undefined>(
    url: string,
    options?: any,
  ) {
    const deleteOptionsWithDefaults: UseFetchOptions<DeleteResT> = {
      method: 'DELETE',
      ...optionsWithDefaults,
      ...options,
    }

    return await useFetchWrapper<DeleteResT>(url, deleteOptionsWithDefaults)
  }

  return {
    getResource,
    postResource,
    putResource,
    patchResource,
    deleteResource,
  }
}
