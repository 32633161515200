export type AsyncShowDialogReturn<T> = Promise<T | null>

export interface BaseDialog {
  type:
    | 'delete'
    | 'auth-expired'
    | 'simulation-draft'
    | 'test-draft'
    | 'assessment-draft'
    | 'input-text'
    | 'read-only-copy'
    | 'leave-without-saving'
    | 'manual-candidate-data'
    | 'text-view'
    | 'confirm'
    | 'simulation-link'
    | 'test-link'
  props?: Record<string, any>
}

export interface DeleteDialog extends BaseDialog {
  type: 'delete'
  props: {
    itemName: string
    additionalText?: string
  }
}

export interface AuthExpiredDialog extends BaseDialog {
  type: 'auth-expired'
}

export interface SimulationDraftDialog extends BaseDialog {
  type: 'simulation-draft'
  props: {
    validationMessages: string[]
  }
}

export interface TestDraftDialog extends BaseDialog {
  type: 'test-draft'
  props: {
    validationMessages: string[]
  }
}

export interface AssessmentDraftDialog extends BaseDialog {
  type: 'assessment-draft'
  props: {
    validationMessages: string[]
  }
}

export interface InputTextDialog extends BaseDialog {
  type: 'input-text'
  props: {
    heading: string
    inputPlaceholder?: string
    message?: string
    confirmActionLabel?: string
  }
}

export interface ReadOnlyCopyDialog extends BaseDialog {
  type: 'read-only-copy'
  props: {
    heading: string
    message: string
    value: string
  }
}

export interface LeaveWithoutSavingDialog extends BaseDialog {
  type: 'leave-without-saving'
}

export interface ManualCandidateDataDialog extends BaseDialog {
  type: 'manual-candidate-data'
}

export interface TextViewDialog extends BaseDialog {
  type: 'text-view'
  props: {
    heading: string
    text: string
  }
}

export interface ConfirmDialog extends BaseDialog {
  type: 'confirm'
  props: {
    heading: string
    message?: string
    confirmButtonLabel?: string
    cancelButtonLabel?: string
  }
}

export interface SimulationLinkDialog extends BaseDialog {
  type: 'simulation-link'
  props: {
    simulationId: string
  }
}

export interface TestLinkDialog extends BaseDialog {
  type: 'test-link'
  props: {
    testId: string
  }
}

export type Dialog =
  | DeleteDialog
  | AuthExpiredDialog
  | SimulationDraftDialog
  | InputTextDialog
  | ReadOnlyCopyDialog
  | LeaveWithoutSavingDialog
  | TestDraftDialog
  | AssessmentDraftDialog
  | ManualCandidateDataDialog
  | TextViewDialog
  | ConfirmDialog
  | SimulationLinkDialog
  | TestLinkDialog

export type DialogWithIsOpen = Dialog & { isOpen: boolean }

const dialogs = ref<Map<string, DialogWithIsOpen>>(new Map())
const dialogResolves = ref<Map<string, Function>>(new Map())

export function useDialogService() {
  function addDialog(dialog: DialogWithIsOpen, resolveFn?: Function) {
    const newId = getNewId()
    dialogs.value.set(newId, dialog)

    if (resolveFn) {
      dialogResolves.value.set(newId, resolveFn)
    }

    return newId
  }

  function getDialogById(id: string) {
    return dialogs.value.get(id)
  }

  function getDialogResolveById(id: string) {
    return dialogResolves.value.get(id)
  }

  function updateDialogById(id: string, dialog: DialogWithIsOpen) {
    const currentDialog = dialogs.value.get(id)

    if (!currentDialog) return

    dialogs.value.set(id, { ...currentDialog, ...dialog })
  }

  function closeDialogById(id: string) {
    const dialog = getDialogById(id)

    if (!dialog) return

    updateDialogById(id, { ...dialog, isOpen: false })
  }

  function removeDialogById(id: string) {
    dialogs.value.delete(id)
    dialogResolves.value.delete(id)
  }

  function resolveDialog(dialogId: string, response: any) {
    const resolveDialogFn = getDialogResolveById(dialogId)

    if (!resolveDialogFn) return

    resolveDialogFn(response)

    closeDialogById(dialogId)

    // Remove after 1.5s because of close animation
    setTimeout(() => removeDialogById(dialogId), 1500)
  }

  function showDialog<ReturnT>(dialog: Dialog): AsyncShowDialogReturn<ReturnT> {
    return new Promise((resolve) => {
      addDialog(
        {
          ...dialog,
          isOpen: true,
        },
        resolve,
      )
    })
  }

  return {
    dialogs,
    showDialog,
    getDialogById,
    updateDialogById,
    resolveDialog,
  }
}
