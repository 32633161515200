<template>
  <AppDialog
    v-model="isDialogOpen"
    :header="heading"
    @click:close="$emit('close')"
  >
    <div class="overflow-y-auto studio-scrollbar">
      <p class="text-sm whitespace-pre-wrap text-surface-800">
        {{ text }}
      </p>
    </div>

    <template #actions>
      <AppButtonSecondary
        label="Close"
        @click="handleClose"
      />
    </template>
  </AppDialog>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  heading: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: false,
    default: undefined,
  },
})

const emit = defineEmits(['update:modelValue', 'resolve', 'close'])

const isDialogOpen = computed({
  get() {
    return props.modelValue
  },
  set(value: boolean) {
    emit('update:modelValue', value)
  },
})

function handleClose() {
  emit('close', null)
  isDialogOpen.value = false
}
</script>
