import { AuthoringStatus } from '~/types/AuthoringStatus'
import { type Unsaved } from '~/types/Unsaved'
import {
  type Test,
  type TestGradingCategory,
  type TestGradingCategoryLevel,
  type Question,
  TestFormat,
  type Choice,
  type WrittenQuestionGradingCategory,
  type WrittenQuestionGradingCategoryLevel,
  CategoryScoreAllocation,
  CategoryScoreWeighting,
} from '~/types/Test'
import { ColorOption } from '~/types/Colors'

export const testDefault: Required<Unsaved<Test>> = {
  authoringStatus: AuthoringStatus.Draft,
  format: TestFormat.Mcq,
  testType: null,
  title: '',
  isPublished: false,
  heading: '',
  requiresBlurReporting: false,
  useTimeLimit: false,
  durationMinutes: null,
  forceFullScreen: false,
  introduction: '',
  objective: '',
  questions: [],
  categoryScoreAllocation: CategoryScoreAllocation.ActualUserScore,
  categoryScoreWeighting: CategoryScoreWeighting.Points,
  rubric: [],
  gradingType: null,
  maxAvailablePoints: 0,
  shouldRandomiseQuestionOrder: false,

  // MCQ ------------------------------
  shouldRandomiseResponseOptions: false,

  // MCQ & Written ------------------------------
  hasConfidenceRating: false,

  // Written ------------------------------
  uploadedFile: null,

  // Defaults ------------------------------
  choicesDefault: null,
  confidenceRatingsDefault: {
    5: [-1, 1],
    4: [-1, 1],
    3: [-1, 1],
    2: [-1, 1],
    1: [-1, 1],
  },

  primaryWorkspaceId: '',
  workspaces: [],
  isGlobal: false,
}

export const gradingCategoryDefault: Required<TestGradingCategory> = {
  id: '',
  authoringStatus: AuthoringStatus.Draft,
  title: '',
  levels: [],
  weighting: 0,
  displayColor: ColorOption.Primary,
  maxAvailablePoints: 0,
}

export const gradingCategoryLevelDefault: Required<TestGradingCategoryLevel> = {
  id: '',
  authoringStatus: AuthoringStatus.Draft,
  level: null,
  requiredPercentage: null,
  title: '',
  description: '',
}

export const questionDefault: Unsaved<Required<Question>> = {
  authoringStatus: AuthoringStatus.Draft,
  displayIndex: -1,
  required: true,
  text: '',
  heading: null,
  imageUrl: null,
  externalContentUrl: null,
  externalContentType: null,
  simulationId: null,
  requiresGradingNote: false,
  gradingNoteInstructions: '',
  rubric: [],
  gradingContext: {
    type: null,
    text: '',
  },
  testGradingCategoryId: null,

  // MCQ
  choiceType: null,

  // Written & MCQ
  confidenceRating: {
    5: [0, 0],
    4: [0, 0],
    3: [0, 0],
    2: [0, 0],
    1: [0, 0],
  },

  // MCQ & Likert
  choices: [],
}

export const choiceDefault: Required<Choice> = {
  id: '',
  text: '',
  displayIndex: 0,

  // MCQ
  correct: false,
  imageUrl: '',
  points: 0,

  // Likert
  value: null,
}

export const responseGradingDefault: Required<
  Unsaved<WrittenQuestionGradingCategory>
> = {
  testGradingCategoryId: '',
  levels: [],
}

export const writtenResponseGradingCategoryLevelDefault: Required<WrittenQuestionGradingCategoryLevel> =
  {
    id: '',
    gradingStatements: [],
    description: '',
    requiredPoints: null,
    level: -1,
  }
