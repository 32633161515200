interface StoreManagerOptions {
  refreshInterval?: MaybeRefOrGetter<number>
}

export function useStoreManager(
  watcherPredicate: Ref<unknown> | ComputedRef<unknown>,
  initialiseFn: () => Promise<boolean>,
  resetFn: () => void,
  refreshFn?: () => Promise<void>,
  options?: StoreManagerOptions,
) {
  const $isInitialised = ref(false)
  const $isInitialising = ref(false)
  const $isUpdating = ref(false)

  const $initialise = useGlobalPromise(async function () {
    $isInitialised.value = false
    $isInitialising.value = true

    const initialised = await initialiseFn()

    $isInitialised.value = initialised
    $isInitialising.value = false
  })

  async function $ensureInitialised() {
    if ($isInitialising.value || !$isInitialised.value) {
      await $initialise()
    }
  }

  // REFRESH & POLLING ========================================

  async function $refresh() {
    if (!refreshFn) return

    $isUpdating.value = true
    await refreshFn()
    $isUpdating.value = false
  }

  let refreshPoll = {
    start: () => {},
    stop: () => {},
  }

  if (refreshFn) {
    refreshPoll = usePoll(
      $refresh,
      options?.refreshInterval ?? 2 * 60 * 1000,
      true,
    )

    const isOnline = useOnlineStatus()

    watch(isOnline, (online) => {
      if (online) {
        refreshPoll.start()
      } else {
        refreshPoll.stop()
      }
    })
  }

  // RESET ========================================

  function $reset() {
    resetFn()
    $isInitialised.value = false
    $isInitialising.value = false
    $isUpdating.value = false
  }

  // WATCHER ========================================

  watch(
    () => watcherPredicate.value,
    (predicate) => {
      $reset()

      if (!predicate) {
        refreshPoll.stop()
        return
      }

      $ensureInitialised()
      refreshPoll.start()
    },
    { immediate: true },
  )

  return {
    $isInitialised,
    $isInitialising,
    $isUpdating,
    $initialise,
    $ensureInitialised,
    $refresh,
    $startRefresh: refreshPoll.start,
    $stopRefresh: refreshPoll.stop,
    $reset,
  }
}
