export function toTitleCase(str: string): string {
  // separate into words array
  const words = str.split(' ')

  // capitalize each word
  const titleCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })

  // join the words back together
  return titleCaseWords.join(' ')
}
