import moment from 'moment'

// GET RELATIVE DATE OUTPUT
export function getRelativeDateByUtc(utcDate) {
  const momentDate = moment(utcDate)

  const calendarOptions = {
    sameDay: '[Today] h:mmA',
    nextDay: '[Tomorrow] h:mmA',
    nextWeek: 'ddd h:mmA',
    lastDay: '[Yesterday] h:mmA',
    lastWeek: '[Last] ddd h:mmA',
    sameElse: 'D MMM YYYY h:mmA',
  }

  return momentDate.calendar(null, calendarOptions)
}

export function getTableFriendlyDateFromUtc(utcDate) {
  const momentDate = moment(utcDate)

  const calendarOptions = {
    sameDay: '[Today], h:mmA',
    nextDay: '[Tomorrow], h:mmA',
    nextWeek: '[Next] ddd, h:mmA',
    lastDay: '[Yesterday], h:mmA',
    lastWeek: 'dddd, h:mmA',
    sameElse: 'D MMM YYYY',
  }

  return momentDate.calendar(null, calendarOptions)
}

export function getTableFullDateFromUtc(utcDate) {
  return moment(utcDate).format('ddd, D MMM, YYYY, h:mmA')
}

// SORT FN
export function dateSort(dateA, dateB) {
  const momentA = moment(dateA)
  const momentB = moment(dateB)

  return momentB.diff(momentA)
}

// EMAIL VIEW DATES
export function getFullEmailDateStringFromDate(dateObj) {
  return moment(dateObj).format('ddd, MMM D, YYYY [at] h:mmA')
}

export function getShortEmailDateStringFromDate(dateObj) {
  const momentDate = moment(dateObj)

  const calendarOptions = {
    sameDay: 'H:mm',
    lastDay: '[Yesterday]',
    sameElse: 'MMM D',
  }

  return momentDate.calendar(null, calendarOptions)
}

export function getRandomPastDateByMinutes(
  upperBoundInMinutes,
  lowerBoundInMinutes,
  presentTime,
) {
  const workingPresentTime = moment(presentTime)
  return workingPresentTime.subtract(
    randomIntFromInterval(upperBoundInMinutes, lowerBoundInMinutes),
    'minutes',
  )
}

export function getArrayOfOffsetTimestamps(
  upperBoundInMinutes,
  lowerBoundInMinutes,
  arrayLength,
  presentTime = moment(),
) {
  const workingPresentTime = moment(presentTime)
  const output = []
  for (let i = 0; i < arrayLength; i++) {
    output.push(
      getRandomPastDateByMinutes(
        upperBoundInMinutes,
        lowerBoundInMinutes,
        workingPresentTime,
      ),
    )
  }
  output.sort((a, b) => dateSort(a, b))
  return output
}

export function getMomentDateNow() {
  return moment()
}

export function getTimestampFromMomentDate(momentDate) {
  return momentDate.toISOString()
}

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function getMomentDateFrom24HourString(timeString) {
  return moment(timeString, 'HH:mm')
}

export function getAdjustedDate(actualStartDate, simulationStartDate) {
  const actualStartMoment = moment(actualStartDate)
  const actualPresentMoment = moment()
  const simulationStartMoment = moment(simulationStartDate)
  const offset = actualPresentMoment.diff(actualStartMoment)
  return simulationStartMoment.add(offset, 'milliseconds')
}

export function getFormattedDurationByMillisecond(durationInMilliseconds) {
  // eslint-disable-next-line import/no-named-as-default-member
  const momentDuration = moment.duration(durationInMilliseconds)
  const durationMinutes =
    momentDuration.minutes() < 10
      ? `0${momentDuration.minutes()}`
      : momentDuration.minutes()
  const durationSeconds =
    momentDuration.seconds() < 10
      ? `0${momentDuration.seconds()}`
      : momentDuration.seconds()
  return `${momentDuration.hours()}:${durationMinutes}:${durationSeconds}`
}

export function getFormattedDurationFromMilliseconds(durationInMilliseconds) {
  const momentDate = moment(durationInMilliseconds)

  // remove utc/timezone offset
  momentDate.subtract(momentDate.utcOffset(), 'minutes')

  // if hours are greater than 0, show hours, else don't
  if (momentDate.hours() > 0) {
    return momentDate.format('k:mm:ss')
  } else {
    return momentDate.format('m:ss')
  }
}
