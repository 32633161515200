import type { ToastMessageOptions } from 'primevue/toast'
import { useToast } from 'primevue/usetoast'

interface ToastMessage {
  heading?: string
  message: string
  life?: number
  closeable?: boolean
  type?: 'default' | 'error' | string
}

export function useToastNotifications() {
  const toast = useToast()

  function transformToastMessageToPVToast(
    message: ToastMessage,
  ): ToastMessageOptions {
    return {
      severity: 'info',
      summary: message.heading,
      detail: message.message,
      life: message.life,
      closable: message.closeable,
      group: message.type || 'default',
    }
  }

  function addToast(message: ToastMessage) {
    toast.add(transformToastMessageToPVToast(message))
  }

  function removeToast(message: ToastMessage) {
    toast.remove(transformToastMessageToPVToast(message))
  }

  function removeToastsByType(type: string) {
    toast.removeGroup(type)
  }

  function removeAllToasts() {
    toast.removeAllGroups()
  }

  return {
    addToast,
    removeToast,
    removeToastsByType,
    removeAllToasts,
  }
}
