import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import { defu } from 'defu'
import { useWorkspaceStore } from '~/stores/workspace'
import { assessmentDefault } from '~/static/data/assessments/assessmentDefaults'
import { type Assessment } from '~/types/Assessment'
import { StateError } from '~/errors/StateError'
import { NotFoundError } from '~/errors/NotFoundError'
import { AuthoringStatus } from '~/types/AuthoringStatus'
// import { useUserStore } from '~/stores/user'

export const useAssessmentStore = defineStore('assessment', () => {
  const AssessmentService = assessmentService()
  const { addToast } = useToastNotifications()

  // OTHER STORES ========================================
  const workspaceStore = useWorkspaceStore()
  // const userStore = useUserStore()

  // STATE ==============================================
  const assessments = ref<Assessment[]>([])

  const assessmentCount = computed<number>(() => assessments.value.length)

  const publishedAssessmentCount = computed(() => {
    return assessments.value.filter((assessment) => assessment.isPublished)
      .length
  })

  const unpublishedAssessmentCount = computed(() => {
    return assessments.value.filter(
      (assessment) =>
        !assessment.isPublished &&
        assessment.authoringStatus !== AuthoringStatus.Draft,
    ).length
  })

  const draftAssessmentCount = computed(() => {
    return assessments.value.filter(
      (assessment) => assessment.authoringStatus === AuthoringStatus.Draft,
    ).length
  })

  const latestUpdatedAtTimestamp = useLatestUpdatedAtTimestamp(assessments)

  // CRUD ========================================
  function getAssessmentById(assessmentId: string) {
    const response = assessments.value.find(
      (assessment) => assessment.id === assessmentId,
    )

    if (!response) {
      Sentry.captureException(
        new NotFoundError(`Assessment with ID ${assessmentId} not found`),
      )
    }

    return response
  }

  async function createAssessment(assessment: Partial<Assessment>) {
    if (!workspaceStore.currentWorkspaceId) {
      const error = new StateError(
        'Error creating assessment. No workspace defined.',
      )

      Sentry.captureException(error)

      return { data: null, error }
    }

    const { data, error } = await AssessmentService.postAssessment(
      workspaceStore.currentWorkspaceId,
      defu(
        { primaryWorkspaceId: workspaceStore.currentWorkspaceId },
        assessment,
        assessmentDefault,
      ),
    )

    if (error !== null) {
      return { data, error }
    }

    assessments.value.push(data)

    return { data, error }
  }

  async function updateAssessmentById(
    assessmentId: string,
    assessment: Partial<Assessment>,
  ) {
    if (!workspaceStore.currentWorkspaceId) {
      const error = new StateError(
        `The assessment with ID ${assessmentId} could not be updated because no workspace was defined.`,
      )

      Sentry.captureException(error)

      return { data: null, error }
    }

    const { data, error } = await AssessmentService.updateAssessmentById(
      workspaceStore.currentWorkspaceId,
      assessmentId,
      assessment,
    )

    if (error != null) {
      return { data, error }
    }

    const index = assessments.value.findIndex(
      (assessment) => assessment.id === assessmentId,
    )
    assessments.value[index] = { ...assessments.value[index], ...data }

    return { data, error }
  }

  async function deleteAssessmentById(assessmentId: string) {
    if (!workspaceStore.currentWorkspaceId) {
      const error = new StateError(
        `The assessment with ID ${assessmentId} could not be deleted because no workspace was defined.`,
      )

      Sentry.captureException(error)

      return { error }
    }

    const { error } = await AssessmentService.deleteAssessmentById(
      workspaceStore.currentWorkspaceId,
      assessmentId,
    )

    if (error !== null) {
      return { error }
    }

    assessments.value = assessments.value.filter(
      (assessment) => assessment.id !== assessmentId,
    )

    return { error }
  }

  function getAssessmentsByTestId(testId: string) {
    return assessments.value.filter((assessment) =>
      assessment.tests.map((t) => t.testId).includes(testId),
    )
  }

  // API CALLS ========================================
  async function fetchAssessmentsFromServer(workspaceId: string) {
    if (!workspaceId) return

    // Fetch assessments from the API
    const { data, error } =
      await AssessmentService.getWorkspaceAssessments(workspaceId)

    if (error !== null) {
      addToast({
        type: 'error',
        heading: 'Failed to fetch assessments',
        message: 'There was an issue fetching assessments.',
        life: 5000,
      })
      return
    }

    assessments.value = data.local
  }

  async function fetchAssessmentUpdatesFromServer(workspaceId: string) {
    if (!workspaceId) return

    if (!latestUpdatedAtTimestamp.value) {
      Sentry.captureException(
        new StateError(
          'No latest updated at timestamp found. This should not happen.',
        ),
      )
      return
    }

    const { data, error } =
      await AssessmentService.getWorkspaceAssessmentUpdates(
        workspaceId,
        latestUpdatedAtTimestamp.value,
      )

    if (error !== null) {
      addToast({
        type: 'error',
        heading: 'Error refreshing assessments',
        message:
          'Assessments could not be refreshed. If the problem persists, please try reloading the page.',
        life: 5000,
      })
      return
    }

    assessments.value = upsertArrayById(assessments.value, data.local)
  }

  function reset() {
    assessments.value = []
  }

  const {
    $isInitialised,
    $isInitialising,
    $isUpdating,
    $initialise,
    $ensureInitialised,
    $reset,
    $refresh,
  } = useStoreManager(
    computed(() => workspaceStore.currentWorkspaceId),

    async () => {
      await workspaceStore.$ensureInitialised()

      reset()

      if (!workspaceStore.currentWorkspaceId) return false

      await fetchAssessmentsFromServer(workspaceStore.currentWorkspaceId)

      return true
    },

    reset,

    async () => {
      if (!workspaceStore.currentWorkspaceId) return

      await fetchAssessmentUpdatesFromServer(workspaceStore.currentWorkspaceId)
    },
  )

  return {
    $reset,
    $refresh,
    $ensureInitialised,
    $initialise,
    $isInitialising,
    $isInitialised,
    $isUpdating,
    assessments,
    assessmentCount,
    publishedAssessmentCount,
    unpublishedAssessmentCount,
    draftAssessmentCount,
    getAssessmentById,
    updateAssessmentById,
    createAssessment,
    deleteAssessmentById,
    getAssessmentsByTestId,
  }
})
