import {
  type Assessment,
  type AssessmentTestConnection,
  type CategoryTestConnection,
  type AssessmentGradingCategory,
  type AssessmentGradingCategoryLevel,
  TestScoreWeighting,
} from '~/types/Assessment'
import { AuthoringStatus } from '~/types/AuthoringStatus'
import { ColorOption } from '~/types/Colors'
import type { Unsaved } from '~/types/Unsaved'

export const assessmentDefault: Required<Unsaved<Assessment>> = {
  authoringStatus: AuthoringStatus.Draft,
  title: '',
  isPublished: false,
  objective: '',
  completionTrigger: null,
  requiresEmailConfirmation: false,
  requiresIntegrityPledge: false,
  gradingType: null,
  rubric: [],
  useNumericalScoring: false, // no idea what this does from a business logic perspective
  categoryScoreAllocation: null,
  categoryScoreWeighting: null,
  maxAvailablePoints: 0,
  tests: [],
  primaryWorkspaceId: '',
  workspaces: [],
  isGlobal: false,
}

export const assessmentTestConnectionDefault: Required<
  Unsaved<AssessmentTestConnection>
> = {
  testId: '',
  isRequired: false,
}

export const assessmentGradingCategoryDefault: Required<AssessmentGradingCategory> =
  {
    id: '',
    authoringStatus: AuthoringStatus.Draft,
    title: '',
    testConnections: [],
    testScoreWeighting: TestScoreWeighting.Points,
    levels: [],
    displayColor: ColorOption.Primary,
    maxAvailablePoints: 0,
    weighting: null,
  }

export const gradingCategoryTestConnectionDefault: Required<
  Unsaved<CategoryTestConnection>
> = {
  testId: '',
  weighting: null, // 1 - 100
  useOverallTestScore: true, // Instead of specified test categories
  categories: [],
}

export const assessmentGradingCategoryLevelDefault: Required<
  Unsaved<AssessmentGradingCategoryLevel>
> = {
  authoringStatus: AuthoringStatus.Draft,
  level: null,
  requiredPercentage: null,
  requiredPoints: null,
  title: '',
  description: '',
}
