export function useGlobalPromise<T>(promiseFunction: () => Promise<T>) {
  let globalPromise: Promise<T> | null = null

  async function callFunction(): Promise<T> {
    if (!globalPromise) {
      globalPromise = promiseFunction()
    }

    const returnValue = await globalPromise

    globalPromise = null

    return returnValue
  }

  return callFunction
}
