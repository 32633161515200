import { httpService } from '~/services/httpService'
import type { Email } from '~/types/Email'
import type { Chat } from '~/types/Chat'
import type { Candidate } from '~/types/Candidate'
import type {
  Interaction,
  InteractionSummary,
  InteractionWithoutThread,
  MaybePopulatedInteraction,
  MaybePopulatedInteractionWithoutThread,
  PostInteractionBody,
  Thread,
} from '~/types/Interaction'
import type { Unsaved } from '~/types/Unsaved'
import type { AnyError } from '~/types/Error'

export function interactionService() {
  const config = useRuntimeConfig()
  const serverUrl = config.public.serverUrl

  const { getResource, postResource } = httpService<Chat | Email>(serverUrl)

  // INTERACTION ========================================

  async function getInteractionById(
    simulationId: string,
    interactionId: string,
  ) {
    const response = await getResource<Interaction>(
      `simulations/${simulationId}/interactions/${interactionId}`,
      { useFallbackAuth: true },
    )
    return response
  }

  async function getInteractionsBySimulationId(simulationId: string) {
    const response = await getResource<InteractionWithoutThread[]>(
      `simulations/${simulationId}/interactions`,
    )
    return response
  }

  async function getPopulatedInteractionsBySimulationId(simulationId: string) {
    const response = await getResource<
      MaybePopulatedInteractionWithoutThread[]
    >(`simulations/${simulationId}/interactions?populate=candidate`)
    return response
  }

  async function getInteractionsUpdatesBySimulationId(
    simulationId: string,
    lastUpdateTimestamp: string,
  ) {
    const response = await getResource<Interaction[]>(
      `simulations/${simulationId}/interactions`,
      {
        query: {
          updatedAtGt: lastUpdateTimestamp,
        },
      },
    )
    return response
  }

  async function getPopulatedInteractionById(
    simulationId: string,
    interactionId: string,
  ) {
    const response = await getResource<MaybePopulatedInteraction>(
      `simulations/${simulationId}/interactions/${interactionId}?populate=candidate`,
    )
    return response
  }

  async function getWorkspaceInteractionsSummary(workspaceId: string) {
    const response = await getResource<InteractionSummary>(
      `workspaces/${workspaceId}/interactions-summary`,
    )
    return response
  }

  // DO AN INTERACTION ==================================

  async function postInteraction(
    simulationId: string,
    interaction: PostInteractionBody,
  ) {
    const response = await postResource<Interaction, PostInteractionBody>(
      `/simulations/${simulationId}/interactions`,
      interaction,
      { useFallbackAuth: true },
    )
    return response
  }

  async function postInteractionCandidateData(
    simulationId: string,
    interactionId: string,
    candidate: Unsaved<Candidate>,
  ) {
    const response = await postResource<Candidate, Unsaved<Candidate>>(
      `/simulations/${simulationId}/interactions/${interactionId}/candidates`,
      candidate,
      { useFallbackAuth: true },
    )
    return response
  }

  async function startInteraction(
    simulationId: string,
    interactionId: string,
    thread: Thread,
  ) {
    const response = await postResource<Thread>(
      `/simulations/${simulationId}/interactions/${interactionId}/start`,
      thread,
      { useFallbackAuth: true },
    )
    return response
  }

  async function continueInteraction(
    simulationId: string,
    interactionId: string,
    thread: Thread,
  ) {
    const response = await postResource<Thread>(
      `/simulations/${simulationId}/interactions/${interactionId}/continue`,
      thread,
      { useFallbackAuth: true },
    )
    return response
  }

  async function verifyProceed(
    simulationId: string,
    interactionId: string,
    candidateId: string,
  ) {
    const response = await getResource<string | boolean>(
      `/simulations/${simulationId}/interactions/${interactionId}/candidates/${candidateId}/verify-proceed`,
      { useFallbackAuth: true },
    )

    // Convert the string 'true' to boolean true
    if (response.data !== null && typeof response.data === 'string') {
      response.data = (response.data === 'true') as boolean
    }

    return response as
      | { data: boolean; error: null }
      | { data: null; error: AnyError }
  }

  // CANDIDATES ========================================
  async function getCandidates(queryParameters: Record<string, string> = {}) {
    const response = await getResource<Candidate[]>('/interactees', {
      query: queryParameters,
      useFallbackAuth: true,
    })
    return response
  }

  async function getCandidateById(
    simulationId: string,
    interactionId: string,
    candidateId: string,
  ) {
    const response = await getResource<Candidate>(
      `simulations/${simulationId}/interactions/${interactionId}/candidates/${candidateId}`,
      { useFallbackAuth: true },
    )
    return response
  }

  return {
    getCandidates,
    getCandidateById,
    getInteractionById,
    getInteractionsBySimulationId,
    getPopulatedInteractionById,
    getPopulatedInteractionsBySimulationId,
    getInteractionsUpdatesBySimulationId,
    postInteraction,
    postInteractionCandidateData,
    startInteraction,
    continueInteraction,
    verifyProceed,
    getWorkspaceInteractionsSummary,
  }
}
