import type { DatabaseMetadata } from './DatabaseMetadata'
import type { WorkspaceSummary } from './Workspace'

export enum UserRole {
  Super = 'super',
  Standard = 'standard',
}

export interface User extends DatabaseMetadata {
  email: string
  firstName: string
  lastName: string
  role: UserRole
}

export interface UserWithWorkspaceSummaries extends User {
  workspaces: WorkspaceSummary[]
}

export interface UserWithWorkspaceIds extends User {
  workspaces: string[]
}

export interface SignUpData {
  email: string
  firstName: string
  lastName: string
  auth0Id: string
}
