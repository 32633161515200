import { default as _91assessmentId_937JcHhOEvKaMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/assessments/[assessmentId].vue?macro=true";
import { default as create1SBCBwmkG5Meta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/assessments/create.vue?macro=true";
import { default as index2lBq4jUJOGMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/assessments/index.vue?macro=true";
import { default as _91characterId_93DjCLSe1cpPMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/characters/[characterId].vue?macro=true";
import { default as createFEP7lvgvfIMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/characters/create.vue?macro=true";
import { default as index9qbh8U05PhMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/characters/index.vue?macro=true";
import { default as _91environmentId_934tGJ7fvMB1Meta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/environments/[environmentId].vue?macro=true";
import { default as createRqnux3ecfiMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/environments/create.vue?macro=true";
import { default as indexQMmRs9TTCQMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/environments/index.vue?macro=true";
import { default as indexi29hSWSjUwMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/index.vue?macro=true";
import { default as indexTj2XlhStPxMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/simulations/[simulationId]/index.vue?macro=true";
import { default as _91interactionId_93nN2bupX7MuMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/simulations/[simulationId]/interactions/[interactionId].vue?macro=true";
import { default as indexga27mLoSu0Meta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/simulations/[simulationId]/interactions/index.vue?macro=true";
import { default as previewaZQv1dy1TPMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/simulations/[simulationId]/preview.vue?macro=true";
import { default as createQGgS0vZaI8Meta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/simulations/create.vue?macro=true";
import { default as indexMTZxQhui1bMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/simulations/index.vue?macro=true";
import { default as tags5WKwFahHP3Meta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/tags.vue?macro=true";
import { default as indexclTwYwBi3BMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/tests/[testId]/index.vue?macro=true";
import { default as _91testSubmissionId_931rrB5aBT4FMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/tests/[testId]/submissions/[testSubmissionId].vue?macro=true";
import { default as index8nlb5dBUifMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/tests/[testId]/submissions/index.vue?macro=true";
import { default as createSMVSyoh3rkMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/tests/create.vue?macro=true";
import { default as indexua4FXYjEQ1Meta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/tests/index.vue?macro=true";
import { default as workspacenUeDXFypvHMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/workspace.vue?macro=true";
import { default as auth_45callback0AywZXmXtEMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/auth-callback.vue?macro=true";
import { default as _91callSlug_93S36B4Uq5Q1Meta } from "/home/runner/work/studio-web-app/studio-web-app/pages/demo/call/[callSlug].vue?macro=true";
import { default as _91simulationId_93iEeVON9z7vMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/demo/simulation/[simulationId].vue?macro=true";
import { default as iframe_45simO9wvrQLArHMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/iframe-sim.vue?macro=true";
import { default as iframe5x6sC4DHRrMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/iframe.vue?macro=true";
import { default as indexEbl9NWLqmuMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/index.vue?macro=true";
import { default as signin4LqZXEXFliMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/signin.vue?macro=true";
import { default as signupviTP7Srb5jMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/signup.vue?macro=true";
import { default as index84uFOyFdOmMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/simulation-assessment/index.vue?macro=true";
import { default as _91simulationId_93Gow5b50SC3Meta } from "/home/runner/work/studio-web-app/studio-web-app/pages/simulation/[simulationId].vue?macro=true";
import { default as charactersUJR0zCOWZ7Meta } from "/home/runner/work/studio-web-app/studio-web-app/pages/system-admin/characters.vue?macro=true";
import { default as simulationsPt377N5ddbMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/system-admin/simulations.vue?macro=true";
import { default as usersvLkdzqKlicMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/system-admin/users.vue?macro=true";
import { default as workspacesXxefyZ8mCtMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/system-admin/workspaces.vue?macro=true";
import { default as _91testId_93hS2OYUxnWwMeta } from "/home/runner/work/studio-web-app/studio-web-app/pages/test/[testId].vue?macro=true";
export default [
  {
    name: "workspaceId-assessments-assessmentId",
    path: "/:workspaceId()/assessments/:assessmentId()",
    meta: _91assessmentId_937JcHhOEvKaMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/assessments/[assessmentId].vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-assessments-create",
    path: "/:workspaceId()/assessments/create",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/assessments/create.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-assessments",
    path: "/:workspaceId()/assessments",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/assessments/index.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-characters-characterId",
    path: "/:workspaceId()/characters/:characterId()",
    meta: _91characterId_93DjCLSe1cpPMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/characters/[characterId].vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-characters-create",
    path: "/:workspaceId()/characters/create",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/characters/create.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-characters",
    path: "/:workspaceId()/characters",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/characters/index.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-environments-environmentId",
    path: "/:workspaceId()/environments/:environmentId()",
    meta: _91environmentId_934tGJ7fvMB1Meta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/environments/[environmentId].vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-environments-create",
    path: "/:workspaceId()/environments/create",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/environments/create.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-environments",
    path: "/:workspaceId()/environments",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/environments/index.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId",
    path: "/:workspaceId()",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/index.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-simulations-simulationId",
    path: "/:workspaceId()/simulations/:simulationId()",
    meta: indexTj2XlhStPxMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/simulations/[simulationId]/index.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-simulations-simulationId-interactions-interactionId",
    path: "/:workspaceId()/simulations/:simulationId()/interactions/:interactionId()",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/simulations/[simulationId]/interactions/[interactionId].vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-simulations-simulationId-interactions",
    path: "/:workspaceId()/simulations/:simulationId()/interactions",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/simulations/[simulationId]/interactions/index.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-simulations-simulationId-preview",
    path: "/:workspaceId()/simulations/:simulationId()/preview",
    meta: previewaZQv1dy1TPMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/simulations/[simulationId]/preview.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-simulations-create",
    path: "/:workspaceId()/simulations/create",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/simulations/create.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-simulations",
    path: "/:workspaceId()/simulations",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/simulations/index.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-tags",
    path: "/:workspaceId()/tags",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/tags.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-tests-testId",
    path: "/:workspaceId()/tests/:testId()",
    meta: indexclTwYwBi3BMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/tests/[testId]/index.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-tests-testId-submissions-testSubmissionId",
    path: "/:workspaceId()/tests/:testId()/submissions/:testSubmissionId()",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/tests/[testId]/submissions/[testSubmissionId].vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-tests-testId-submissions",
    path: "/:workspaceId()/tests/:testId()/submissions",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/tests/[testId]/submissions/index.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-tests-create",
    path: "/:workspaceId()/tests/create",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/tests/create.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-tests",
    path: "/:workspaceId()/tests",
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/tests/index.vue").then(m => m.default || m)
  },
  {
    name: "workspaceId-workspace",
    path: "/:workspaceId()/workspace",
    meta: workspacenUeDXFypvHMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/[workspaceId]/workspace.vue").then(m => m.default || m)
  },
  {
    name: "auth-callback",
    path: "/auth-callback",
    meta: auth_45callback0AywZXmXtEMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/auth-callback.vue").then(m => m.default || m)
  },
  {
    name: "demo-call-callSlug",
    path: "/demo/call/:callSlug()",
    meta: _91callSlug_93S36B4Uq5Q1Meta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/demo/call/[callSlug].vue").then(m => m.default || m)
  },
  {
    name: "demo-simulation-simulationId",
    path: "/demo/simulation/:simulationId()",
    meta: _91simulationId_93iEeVON9z7vMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/demo/simulation/[simulationId].vue").then(m => m.default || m)
  },
  {
    name: "iframe-sim",
    path: "/iframe-sim",
    meta: iframe_45simO9wvrQLArHMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/iframe-sim.vue").then(m => m.default || m)
  },
  {
    name: "iframe",
    path: "/iframe",
    meta: iframe5x6sC4DHRrMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/iframe.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexEbl9NWLqmuMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "signin",
    path: "/signin",
    meta: signin4LqZXEXFliMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupviTP7Srb5jMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "simulation-assessment",
    path: "/simulation-assessment",
    meta: index84uFOyFdOmMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/simulation-assessment/index.vue").then(m => m.default || m)
  },
  {
    name: "simulation-simulationId",
    path: "/simulation/:simulationId()",
    meta: _91simulationId_93Gow5b50SC3Meta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/simulation/[simulationId].vue").then(m => m.default || m)
  },
  {
    name: "system-admin-characters",
    path: "/system-admin/characters",
    meta: charactersUJR0zCOWZ7Meta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/system-admin/characters.vue").then(m => m.default || m)
  },
  {
    name: "system-admin-simulations",
    path: "/system-admin/simulations",
    meta: simulationsPt377N5ddbMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/system-admin/simulations.vue").then(m => m.default || m)
  },
  {
    name: "system-admin-users",
    path: "/system-admin/users",
    meta: usersvLkdzqKlicMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/system-admin/users.vue").then(m => m.default || m)
  },
  {
    name: "system-admin-workspaces",
    path: "/system-admin/workspaces",
    meta: workspacesXxefyZ8mCtMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/system-admin/workspaces.vue").then(m => m.default || m)
  },
  {
    name: "test-testId",
    path: "/test/:testId()",
    meta: _91testId_93hS2OYUxnWwMeta || {},
    component: () => import("/home/runner/work/studio-web-app/studio-web-app/pages/test/[testId].vue").then(m => m.default || m)
  }
]