import moment from 'moment'

function getDateFromTimeString(timeString: string) {
  if (!timeString) {
    console.warn(
      'No time string provided to useFictitiousTime, using current time',
    )
    return getMomentDateNow()
  }

  const validTimeStringRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/

  if (!validTimeStringRegex.test(timeString)) {
    console.warn(
      'Invalid time string provided to useFictitiousTime, using current time',
    )
    return getMomentDateNow()
  }

  return getMomentDateFrom24HourString(timeString)
}

export function useFictitiousTime(fictitiousStartTimeString: Ref<string>) {
  const fictitiousStartTimeDate = computed(() => {
    return getDateFromTimeString(fictitiousStartTimeString.value)
  })

  const actualStartTime = ref(getMomentDateNow())

  const timeOffsetMs = computed(() => {
    return fictitiousStartTimeDate.value
      .add(1, 'seconds') // fix off by one minute
      .diff(actualStartTime.value)
  })

  function setActualStartTime() {
    actualStartTime.value = moment(getMomentDateNow())
  }

  function getOffsetTimestamp(actualTimestamp: string) {
    return moment(actualTimestamp).add(timeOffsetMs.value, 'ms').format()
  }

  return {
    setActualStartTime,
    actualStartTime,
    timeOffsetMs,
    getOffsetTimestamp,
  }
}
