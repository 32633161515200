import { httpService } from '~/services/httpService'
import type { Candidate } from '~/types/Candidate'
import type { Unsaved } from '~/types/Unsaved'

export function candidateService() {
  const config = useRuntimeConfig()
  const serverUrl = config.public.serverUrl

  const { postResource } = httpService<Candidate>(serverUrl)

  async function upsertCandidate(candidateData: Unsaved<Candidate>) {
    const response = await postResource<Candidate, Unsaved<Candidate>>(
      '/candidates',
      candidateData,
      {
        useFallbackAuth: true,
      },
    )
    return response
  }

  return {
    upsertCandidate,
  }
}
