import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import { useRouteParams } from '@vueuse/router'
import { defu } from 'defu'
import { useUserStore } from './user'
import type { Workspace, WorkspaceWithUsers } from '~/types/Workspace'
import { workspaceDefault } from '~/static/data/workspace/workspaceDefaults'
import { StateError } from '~/errors/StateError'
import { ArgumentError } from '~/errors/ArgumentError'
import { NotFoundError } from '~/errors/NotFoundError'
import { NetworkError } from '~/errors/NetworkError'
import type { User } from '~/types/User'

export const useWorkspaceStore = defineStore('workspace', () => {
  const WorkspaceService = workspaceService()
  const { addToast } = useToastNotifications()

  const router = useRouter()

  // OTHER STORES ========================================================================
  const userStore = useUserStore()

  // STATE ========================================================================
  const workspaceIdInRoute = useRouteParams('workspaceId')
  const currentWorkspace = ref<WorkspaceWithUsers | null>(null)
  const currentWorkspaceId = ref<string | null>(null)
  const workspaceUsers = computed(() => {
    if (!currentWorkspace.value) return []

    return currentWorkspace.value.users
  })
  const workspacePreviousUsers = computed<User[]>(() => {
    if (!currentWorkspace.value) return []

    return currentWorkspace.value.previousUsers
  })

  // CRUD ========================================================================

  async function updateWorkspace(workspaceData: Partial<Workspace>) {
    if (!currentWorkspaceId.value)
      return {
        data: null,
        error: new StateError(
          'Could not update workspace because no workspace ID is defined.',
        ),
      }

    const { data, error } = await WorkspaceService.updateWorkspaceById(
      currentWorkspaceId.value,
      workspaceData,
    )

    if (error !== null) {
      return { data, error }
    }

    currentWorkspace.value = { ...currentWorkspace.value!, ...workspaceData }

    return { data, error }
  }

  async function createWorkspace(workspaceTitle: string) {
    if (!userStore.user?.id) {
      return {
        data: null,
        error: new StateError(
          `Could not create workspace with title ${workspaceTitle} becuase no user is defined.`,
        ),
      }
    }

    if (!workspaceTitle) {
      return {
        data: null,
        error: new ArgumentError(
          'The workspace could not be created because no title was defined.',
        ),
      }
    }

    const { data, error } = await WorkspaceService.postWorkspace({
      ...workspaceDefault,
      title: workspaceTitle,
      createdBy: userStore.user.id,
    })

    if (error !== null) {
      return { data, error }
    }

    await userStore.$refresh()

    return { data, error }
  }

  async function addUsersToWorkspace(userEmails: string[]) {
    if (!currentWorkspaceId.value)
      return {
        data: null,
        error: new StateError(
          'Could not add users to workspace because no workspace ID is defined.',
        ),
      }

    const { data, error } = await WorkspaceService.addUsersToWorkspace(
      currentWorkspaceId.value,
      userEmails,
    )

    if (error !== null) {
      addToast({
        type: 'error',
        heading: 'Could not add users to workspace',
        message:
          'There was an error while adding users to the workspace. Please try again.',
        life: 5000,
      })
      return
    }

    currentWorkspace.value = data
  }

  function getWorkspaceUserById(userId: string) {
    let response = workspaceUsers.value.find((user) => user.id === userId)

    if (!response) {
      response = workspacePreviousUsers.value.find((user) => user.id === userId)
    }

    if (!response) {
      Sentry.captureException(
        new NotFoundError(
          `User with ID ${userId} not found in workspace ${currentWorkspaceId.value}.`,
        ),
      )
    }

    return response
  }

  async function removeUserFromWorkspace(userId: string) {
    if (!currentWorkspaceId.value || !currentWorkspace.value)
      return {
        data: null,
        error: new StateError(
          'Could not remove user from workspace because no workspace ID is defined.',
        ),
      }

    const { error } = await WorkspaceService.removeUserFromWorkspace(
      currentWorkspaceId.value,
      userId,
    )

    if (error !== null) {
      addToast({
        type: 'error',
        heading: 'Could not remove user from workspace',
        message:
          'There was an error while removing the user from the workspace. Please try again.',
        life: 5000,
      })
      return { error }
    }

    const removedUser = currentWorkspace.value.users.find(
      (user) => user.id === userId,
    )

    if (!removedUser) {
      Sentry.captureException(
        new NotFoundError(
          `User with ID ${userId} not found in workspace ${currentWorkspaceId.value}.`,
        ),
      )
    }

    currentWorkspace.value.previousUsers.push(removedUser)

    currentWorkspace.value.users = currentWorkspace.value!.users.filter(
      (user) => user.id !== userId,
    )

    return { error }
  }

  // API CALLS ========================================================================
  async function fetchWorkspaceFromServer(workspaceId: string) {
    if (!workspaceId)
      return {
        data: null,
        error: new ArgumentError(
          'Could not fetch workspace from server. Workspace ID required.',
        ),
      }

    const { data, error } = await WorkspaceService.getWorkspaceById(workspaceId)

    return { data, error }
  }

  // META ACTIONS ========================================================================
  function reset() {
    currentWorkspace.value = null
    currentWorkspaceId.value = null
  }

  const {
    $isInitialised,
    $isInitialising,
    $isUpdating,
    $initialise,
    $ensureInitialised,
    $reset,
  } = useStoreManager(
    computed(() => workspaceIdInRoute.value),

    async () => {
      if (!workspaceIdInRoute.value) return false

      currentWorkspaceId.value = workspaceIdInRoute.value as string

      const { data, error } = await fetchWorkspaceFromServer(
        currentWorkspaceId.value,
      )

      if (error !== null) {
        if (error instanceof NetworkError) {
          if (error.statusCode === 404) {
            showError({
              statusCode: 404,
              statusMessage: 'Workspace not found',
              message: `Workspace with ID ${currentWorkspaceId.value} not found`,
            })
            reset()
            return false
          }
        }
        addToast({
          type: 'error',
          heading: 'Error loading workspace',
          message: `The workspace you were looking for could not be found. Please double check the URL and try again.`,
          life: 5000,
        })
        reset()
        router.push('/')
        return false
      }

      await userStore.$ensureInitialised()

      const isUserInWorkspace = userStore.getIsUserInWorkspace(
        currentWorkspaceId.value,
      )

      if (!isUserInWorkspace) {
        showError({
          statusCode: 403,
          statusMessage:
            'You are not a member of this workspace. Please contact a workspace admin to request access.',
          message: `You are not a member of this workspace. Please contact a workspace admin to request access.`,
        })
        reset()
        return false
      }

      currentWorkspace.value = defu(data, workspaceDefault)

      return true
    },

    reset,

    async () => {
      if (!currentWorkspaceId.value) return

      const { data, error } = await fetchWorkspaceFromServer(
        currentWorkspaceId.value,
      )

      if (error !== null) {
        addToast({
          type: 'error',
          heading: 'Error refreshing workspace',
          message:
            'The workspace could not be refreshed. If the problem persists, please try reloading the page.',
          life: 5000,
        })
        return
      }

      currentWorkspace.value = data
    },
  )

  return {
    currentWorkspace,
    currentWorkspaceId,
    createWorkspace,
    workspaceUsers,
    workspacePreviousUsers,
    updateWorkspace,
    addUsersToWorkspace,
    getWorkspaceUserById,
    removeUserFromWorkspace,
    $reset,
    $initialise,
    $ensureInitialised,
    $isInitialising,
    $isUpdating,
    $isInitialised,
  }
})
